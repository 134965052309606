import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import URL from '../../../../../../URL'

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px',"--Input-decoratorChildHeight": "24px" };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px' };
	const formControlStyle = { my: 0.5 };

	const [editedData, setEditedData] = React.useState({});
	const [isAtaValid, setIsAtaValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsAtaValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Atas/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности ATA
	const checkValidATA = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Atas/ValidAta()?ata='${editedData.ataCode}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsAtaValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = async () =>  {
		if (await checkValidATA()) {
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.ataCode === props.selectedRow.ataCode || await checkValidATA()){
			setIsAtaValid(true);
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};
	
	return (
		<Box>
			<Typography 
				sx={{
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				ATA Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.ataCode && editedData.title && editedData.acFamily ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.ataCode && editedData.title && editedData.acFamily ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={{ ...labelStyle, width: '100%', display: 'flex' }}>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								<Typography>ATA:</Typography>
								<Typography sx={{ color: '#C41C1C', fontSize: '1em', marginLeft: '4px' }}>*</Typography>
							</span>
							<Typography 
								color='danger'
								sx={{ 
									ml: 'auto', 
									mr:2,
									fontSize:14,
									display:isAtaValid === false? 'flex' :'none'
								}}
							>
								ATA is already exist
							</Typography>
						</FormLabel>
						<Input 
							color={!isAtaValid ? 'danger' : 'primary'}
							sx={{...inputStyle, border:isAtaValid ? '1px solid black':'1px solid #C41C1C', color: isAtaValid ? '#000084':'#C41C1C'}} 
							value={editedData?.ataCode ?? ''} 
							onChange={(event) => handleChange('ataCode', event.target.value)} 
						/>
					</FormControl>
				</Grid>
		
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Title:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.title ?? ''}
							onChange={(event) => handleChange('title', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>AC Family:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.acFamilies.map(data=> ({ label: data.acFamily }))}
							value={{ label: editedData.acFamily ?? ''}}
							sx={inputStyle}
							onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
						/>
					</FormControl>
				</Grid>

				<Grid xs={6} sx={{display:'flex', alignItems:'flex-end', justifyContent:'flex-start'}}>
					<Checkbox 
						label='ETOPS Significant'
						color='primary'
						variant='outlined' 
						sx={{
							color:'#000084',
							m:0.5
						}}
						defaultChecked
						checked={editedData.etopsSignificant ? true : false}
						onChange={(event, value) => handleChange('etopsSignificant', event.target.checked)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
