import * as React from 'react';

import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Button from "@mui/joy/Button";
import { CssVarsProvider } from '@mui/joy/styles';

import AS_Submit_Opening_Initializing from './AS_Submit_Opening_Initializing.tsx';
import AS_Initializing from './AS_Initializing.tsx';
import ErrorSuccessfulResponse from '../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../URL.js';

export default function Actual_Structure (props) { 
	const [data, setData] = React.useState<{id:number, acReg:string, acsn:string, acFamily:string, acType:string, operatorCodeICAO:string, operatorName:string, staOperatorBaseLocation:string, Total_Cycles:number, Total_Hours:number, Total_Date:string}[]>([]);
    const [selectedRow, setSelectedRow] = React.useState({id:0});
    const [filterText, setFilterText] = React.useState('');
    const [submitingOpening, setSubmitingOpening] = React.useState(false);
    const [openInitializing, setOpenInitializing] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		fetchData();
	}, []);

    const handleSelectButton = () => {
        if(selectedRow.id != 0){
            setSubmitingOpening(true);
        }
    };
    
    const handleFindComponents = () => {
		//setOpenMaintanaceData(false);
        //handleFindComponentsClick();
    };

	const handleManuals = () => {
		//setOpenMaintanaceData(true);
		//if(openFilters){
			//handleFindComponentsClick();
		//}
	};

    const handleRowSelection = (row) => {
        setSelectedRow(row);
		//setSelectedAC(row);
       // fetchData(row);
    };

    const handleFilterInputChange = (event) => {
        setFilterText(event.target.value);
    }

	React.useEffect(() => {
		if (!openInitializing) {
			handleRowSelection(selectedRow);
		}
	}, [openInitializing]);

	{/** 
    const fetchData = (row) => {
        return fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Initializing?filter=acId eq ${row.id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('asid')}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            if (Object.keys(data.value).length === 0) {
                setIsInitialized(false);
            } else {
                setIsInitialized(true);
            }
        })
        .catch(error => console.error('Ошибка при выполнении API-запроса:', error));
    };
*/}
	const handleExcelButton = () => {
		let url;
		if (filterText.trim() === '') {
			url = `${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure/Xlsx/''`;
		} else {
			url = `${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure/Xlsx/${filterText}`;
		}

		return fetch(url, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob(); 
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Actual_Structure.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};
	
	const filteredRows:any = data.filter((row) => {
		const values = Object.values(row).join('').toLowerCase(); 
		return values.includes(filterText.toLowerCase());
	});

	return (
		<CssVarsProvider disableTransitionOnChange>
			<>
				<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
				{submitingOpening &&(
					<AS_Submit_Opening_Initializing 
						setSubmitingOpening={setSubmitingOpening} 
						selectedRow={selectedRow} 
						setOpenInitializing={setOpenInitializing}
					/>
				)}
				{openInitializing &&(
					<AS_Initializing 
						AS={selectedRow} 
						setOpenInitializing={setOpenInitializing} 
					/>
				)}
				<Box
					sx={{
						position:'absolute',
						width:'100%',
						height:'97%',
						bottom:0,
						border:'1px solid white'
					}}
				>
					<Box
						sx={{
							position:'absolute',
							top:-11,
							left:10,
							fontSize:20,
							color:'black',
							bgcolor:'#848284',
							lineHeight:0.9,
							zIndex:1,
							fontWeight:'bold'
						}}
					>
						Actual Structure - Select Aircrfat Reg. No. - SN:
					</Box>
					<Box
						sx={{
							position:'absolute',
							top:'5%',
							left:'2%',
							bgcolor:'white',
							height:'90%',
							width:'72%',
							overflow:'auto',
							border:'1px solid black',
							zIndex:3,
						}}
					>
						<Table
							hoverRow
							noWrap
							borderAxis='none'
							sx = {{
								tableLayout: 'auto', 
								textAlign:'center',
								"--TableCell-height": "1px",
								"--TableCell-paddingY": "0px",
								lineHeight:'21px',
							}}
						>
							<tbody>
								{filteredRows.length == 0 && (
									<th colSpan={999}>
										<td 
											style={{
												backgroundColor:'white',
												color:'#000076',
												fontSize:'20px',
												fontWeight:'bold',
												textAlign:'left'
											}}
										>
											Selection Not Found !
										</td>
									</th>
								)}
								{filteredRows.map((row) => (
									<tr 
										key={row.id}
										onClick={() => handleRowSelection(row)} 
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#08246b' : 'white',
											color: selectedRow.id === row.id ? 'white' : '#000076',
											fontSize:'20px',
											fontWeight:'bold',
											textAlign:'left'
										}}
									>
										<td>{row.id}</td>
										<td>{row.acReg}</td>
										<td>{row.acsn}</td>
										<td>{row.acFamily}</td>
										<td>{row.acType}</td>
										<td>{row.operatorCodeICAO}</td>
										<td>{row.operatorName}</td>
										<td>{row.staOperatorBaseLocation}</td>
										<td>{row.Total_Cycles}</td>
										<td>{row.Total_Hours}</td>
										<td>{row.Total_Date}</td>
									</tr>
								))}
							</tbody>
						</Table>	
					</Box>
					<Box
						sx={{
							position:'absolute',
							top:'5%',
							right:'2%',
							height:'95%',
							width:'22%',
							display:'flex',
							flexDirection:'column',
							justifyContent:'space-between',
						}}
					>
						
						{isInitialized ? (
							<Box
								sx={{
									display:'flex',
									flexDirection:'column',
									justifyContent:'space-between',
								}}
							>
								<Button
									key='Planning'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
										mb:1
									}}
								>
									Planning
								</Button>
								{permissions.some(permission => permission.window === "Actual") ? (
									<Button
										key='Actual'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											my:1
										}}
									>
										Actual
									</Button>
								):(
									<Box sx={{height:'43px',my:1}}/>
								)}
								{permissions.some(permission => permission.window === "Initializing") ? (
									<Button
										key='Initializing'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											my:1
										}}
									>
										Initializing
									</Button>
								):(
									<Box sx={{height:'43px',my:1}}/>
								)}
								<Button
									key='Reports'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
										mt:5,
										mb:1
									}}
								>
									Reports
								</Button>
								<Button
									key='Engine LLP'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
										my:1
									}}
								>
									Engine LLP
								</Button>
							</Box>
						):(
							<Button
								onClick={handleSelectButton}
								key='Select'
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '18px',
									backgroundColor:'#dedff7',
									borderRight:'2px solid #424142',
									borderBottom:'2px solid #424142',
									borderLeft:'2px solid white',
									borderTop:'2px solid white',
								}}
							>
								Select
							</Button>
						)}	
						<Box
							sx={{
								display:'flex',
								flexDirection:'column',
								justifyContent:'center',
								mb:1.5
							}}	
						>
							<Button
								key='Receipt Info AD, SB, etc.'
								variant="outlined"
								color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
										my:1,
										whiteSpace: 'wrap'
									}}
							>
								Receipt Info AD, SB, etc.
							</Button>	
							<Button
								key='EC'
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '18px',
									backgroundColor:'#dedff7',
									borderRight:'2px solid #424142',
									borderBottom:'2px solid #424142',
									borderLeft:'2px solid white',
									borderTop:'2px solid white',
									mt:1
								}}
							>
								EC
							</Button>	
						</Box>                  
					</Box>
					<Box
						sx={{
							position:'absolute',
							bottom:0,
							left:0,
							height:'5%',
							width:'75%',
							display:'flex',
							alignItems:'center',
							justifyContent:'space-between',
							gap:2
						}}
					>
						<Button
							key='FindComponents'
							variant= "outlined"
							sx={{
								borderRadius: '0px',
								fontSize: '16px',
								fontWeight:'100',
								backgroundColor:'#c6c3ff',
								color:'black',
								height:'20px',
								minHeight:'0px',
								ml:2,
								borderTop:'1px solid white',
								borderRight:'1px solid #424142',
								borderBottom:'1px solid #424142',
								borderLeft:'1px solid white',
								paddingInline:1
							}}
							onClick={() => props.setOpenFilters(!props.openFilters)}
						>
							Find Components
						</Button>
						{selectedRow.id != 0 ? (
							<Button
								key='Manuals'
								variant="outlined"
								onClick={handleManuals}
								sx={{
									borderRadius: '0px',
									fontSize: '16px',
									fontWeight:'100',
									backgroundColor:'#c6c3ff',
									color:'black',
									height:'20px',
									minHeight:'0px',
									ml:2,
									borderTop:'1px solid white',
									borderRight:'1px solid #424142',
									borderBottom:'1px solid #424142',
									borderLeft:'1px solid white',
									paddingInline:1
								}}
							>
								Manuals
							</Button>
						):(<Box sx={{width:'97px',ml:2,}}></Box>)}
						<Button
							key='FindComponents'
							variant="outlined"
							color="neutral"
							onClick={handleExcelButton}
							sx={{
								borderRadius: '0px',
								fontSize: '16px',
								fontWeight:'100',
								backgroundColor:'#c6c3ff',
								color:'black',
								height:'20px',
								minHeight:'0px',
								ml:2,
								borderTop:'1px solid white',
								borderRight:'1px solid #424142',
								borderBottom:'1px solid #424142',
								borderLeft:'1px solid white',
								paddingInline:1
							}}
						>
							Excel
						</Button>
						<Input 
							sx={{
								borderRadius:'0px',
								"--Input-focusedThickness": "0px",
								minHeight:0,
								minWidth:0,
								height:'20px',
								mr:'2%',
								'& input': {
									textAlign: 'center',
								}
							}}
							onChange={handleFilterInputChange}
							value={filterText}
						/>                                               
					</Box>
				</Box>
				{/** 
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						height:'100%'
					}}
				>
					<Typography
						sx={{
							ml:2,
							mb:1,
							fontSize:'24px',
							fontWeight:'500',
							color:'black',
							height:'30px'
						}}
					>
						Actual Structure - Select Aircraft Reg. No. - SN:
					</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'auto 160px',
							height:'100%'
						}}
					>
						<Box
							sx={{
								display: 'grid',
								gridTemplateRows: 'auto 50px',
								//border: '2px solid green' ,
								height:'100%',
							}}
						>
							<Box
								sx={{
								// border: '1px solid grey' ,
									overflow: 'auto', 
									height: 'auto',
									backgroundColor:'white',
									mx:2,
									mb:0
								}}
							>
								<Table
									hoverRow
									noWrap
									size='sm'
									borderAxis='none'
									sx = {{
										tableLayout: 'auto', 
										textAlign:'center',
										"--TableCell-height": "1px",
										"--TableCell-paddingY": "0px",
										lineHeight:'21px'
									}}
								>
									
									<tbody>
										{example.map((row) => (
											<tr 
												key={row.id}
												onClick={() => handleRowSelection(row)} 
												style={{ 
													//backgroundColor: selectedRow === row ? '#08246b' : 'white',
													//color: selectedRow === row ? 'white' : '#000076',
													backgroundColor:'white',
													color:'#000076',
													fontSize:'20px',
													fontWeight:'bold',
													textAlign:'left'
												}}
											>
												<td>{row.id}</td>
												<td>{row.acReg}</td>
												<td>{row.acsn}</td>
												<td>{row.acFamily}</td>
												<td>{row.acType}</td>
												<td>{row.operatorCodeICAO}</td>
												<td>{row.operatorName}</td>
												<td>{row.staOperatorBaseLocation}</td>
												<td>{row.Total_Cycles}</td>
												<td>{row.Total_Hours}</td>
												<td>{row.Total_Date}</td>
											</tr>
										))}
									</tbody>
								</Table>	
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems:'center',
									mr:2
								}}
							>
								<Button
									key='FindComponents'
									variant= "outlined"
									sx={{
										borderRadius: '0px',
										fontSize: '16px',
										fontWeight:'100',
										backgroundColor:'#c6c3ff',
										color:'black',
										width:'155px',
										height:'26px',
										minHeight:'0px',
										ml:2,
										borderTop:'1px solid white',
										borderRight:'1px solid #424142',
										borderBottom:'1px solid #424142',
										borderLeft:'1px solid white',
										paddingInline:0
									}}
									onClick={() => handleFindComponents()}
								>
									Find Components
								</Button>
								{selectedRow.id != '' ? (
									<Button
										key='Manuals'
										variant="outlined"
										onClick={handleManuals}
										sx={{
											borderRadius: '0px',
											fontSize: '16px',
											fontWeight:'100',
											backgroundColor:'#c6c3ff',
											color:'black',
											width:'97px',
											height:'26px',
											minHeight:'0px',
											ml:2,
											borderTop:'1px solid white',
											borderRight:'1px solid #424142',
											borderBottom:'1px solid #424142',
											borderLeft:'1px solid white',
											paddingInline:0
										}}
									>
										Manuals
									</Button>
								):(<Box sx={{width:'97px',ml:2,}}></Box>)}
								<Button
									key='FindComponents'
									variant="outlined"
									color="neutral"
									onClick={handleExcelButton}
									sx={{
										borderRadius: '0px',
										fontSize: '16px',
										fontWeight:'100',
										backgroundColor:'#c6c3ff',
										color:'black',
										width:'97px',
										height:'26px',
										minHeight:'0px',
										ml:2,
										borderTop:'1px solid white',
										borderRight:'1px solid #424142',
										borderBottom:'1px solid #424142',
										borderLeft:'1px solid white',
										paddingInline:0
									}}
								>
									Excel
								</Button>
								<Input 
									sx={{
										borderRadius:'0px',
										"--Input-focusedThickness": "0px",
										width:'135px',
										height:'26px',
										minHeight:'0px',
										ml:'auto',
										'& input': {
											textAlign: 'center',
										}
									}}
									onChange={handleFilterInputChange}
									value={filterText}
								/>                                    
							</Box>                                                           
						</Box>
						<Box
							sx={{
								display:'flex',
								flexDirection:'column',
								justifyContent:'space-between',
								mr:2
							}}	
						>  
							{isInitialized ? (
								<Box
									sx={{
										display:'flex',
										flexDirection:'column',
										justifyContent:'space-between',
									}}
								>
									<Button
										key='Planning'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											mb:1
										}}
									>
										Planning
									</Button>
									{permissions.some(permission => permission.window === "Actual") ? (
										<Button
											key='Actual'
											variant="outlined"
											color="neutral"
											sx={{
												borderRadius: '0px',
												fontSize: '18px',
												backgroundColor:'#dedff7',
												borderRight:'2px solid #424142',
												borderBottom:'2px solid #424142',
												borderLeft:'2px solid white',
												borderTop:'2px solid white',
												my:1
											}}
										>
											Actual
										</Button>
									):(
										<Box sx={{height:'43px',my:1}}/>
									)}
									{permissions.some(permission => permission.window === "Initializing") ? (
										<Button
											key='Initializing'
											variant="outlined"
											color="neutral"
											sx={{
												borderRadius: '0px',
												fontSize: '18px',
												backgroundColor:'#dedff7',
												borderRight:'2px solid #424142',
												borderBottom:'2px solid #424142',
												borderLeft:'2px solid white',
												borderTop:'2px solid white',
												my:1
											}}
										>
											Initializing
										</Button>
									):(
										<Box sx={{height:'43px',my:1}}/>
									)}
									<Button
										key='Reports'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											mt:5,
											mb:1
										}}
									>
										Reports
									</Button>
									<Button
										key='Engine LLP'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											my:1
										}}
									>
										Engine LLP
									</Button>
								</Box>
							):(
								<Button
									onClick={handleSelectButton}
									key='Select'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
									}}
								>
									Select
								</Button>
							)}	
							<Box
								sx={{
									display:'flex',
									flexDirection:'column',
									justifyContent:'center',
									mb:1.5
								}}	
							>
								<Button
									key='Receipt Info AD, SB, etc.'
									variant="outlined"
									color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											my:1
										}}
								>
									Receipt Info AD, SB, etc.
								</Button>	
								<Button
									key='EC'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
										mt:1
									}}
								>
									EC
								</Button>	
							</Box>                  
						</Box>

					</Box>
				</Box>
				*/}
			</>
		</CssVarsProvider>
	);
}
