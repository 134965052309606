import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import URL from '../../../../../../URL';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'14px' }
	const formControlStyle = { my: 1 }
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'16px'};

	const [editedData, setEditedData] = React.useState(props.selectedRow);
	const [groupList, setGroupList] = React.useState<{id:number, name:string, sta:string}[]>([]);
	const [permissionsList, setPermissionsList] = React.useState<{permission:string}[]>([]);
	const [selectedInActiveWindow, setSelectedInActiveWindow] = React.useState();
	const [inActiveWindowsList, setInActiveWindowsList] = React.useState<{id:number, windowName:string, moduleName:string}[]>([]);
	const [isGroupListOpen, setIsGroupListOpen] = React.useState(false);
	const [selectedGroup, setSelectedGroup] = React.useState({name:'', sta:''})
	const [selectedModuleId, setSelectedModuleId] = React.useState(0)

	//Внесение изменений в data
	const handleChange = (property, value) => {
		if (property === 'windowPermission') {
			setEditedData({ ...editedData, [property]: value, activate: value === '' ? 'NO' : 'YES'});
		} else {
			setEditedData({ ...editedData, [property]: value });
		}
		
	};

	const handleOpenGroupList = () => {
		if (isGroupListOpen && selectedGroup.sta != '' && selectedGroup.name != '') {
			setEditedData({
				...editedData,
				groupName: selectedGroup.name,
				groupSta: selectedGroup.sta,
			})	
		}
		setIsGroupListOpen(!isGroupListOpen);
		setSelectedGroup({name:'', sta:''});
	};

	React.useEffect(() => {
		setEditedData(props.selectedRow);
	}, [props.selectedRow]);

	React.useEffect(() => {
		const fetchGroups = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Groups?$select=name,sta`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();	
				setGroupList(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchGroups();
	}, []);

	React.useEffect(() => {
		const fetchPermissions = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSWindowPermissions?$filter=modulePermission eq '${editedData.modulePermission}'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setPermissionsList(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchPermissions();
	}, [editedData.modulePermission]);

	React.useEffect(() => {
		const fetchInActiveWindows = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/InactiveWindows()?secgroup=${editedData.groupName}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setInActiveWindowsList(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if (editedData.groupName){
			fetchInActiveWindows();
		}
	}, [editedData.groupName]);

	const handleSelectedInActiveWindow = async (row) => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityModules?$filter=name eq '${row.moduleName}' and groupName eq '${editedData?.groupName}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setEditedData({...editedData, modulePermission:result.value[0].permission ,moduleName:row.moduleName, windowName:row.windowName})
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
		setSelectedModuleId(row.id)
	}
	
	//Обработчик события добавления новой записи
	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/${selectedModuleId}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					permission: editedData.windowPermission,
					activate: editedData.activate,
					remarks: editedData.remarks
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			return response.json();
		})
		.then(data => {
			props.setSelectedRow(data)
			props.reloadTable();
			props.focusRow();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					permission: editedData.windowPermission,
					activate: editedData.activate,
					remarks: editedData.remarks
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			props.focusRow();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	//Обработчик события удаления выбранной записи
	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.setSelectedRow({id:0})
			setEditedData({})
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	return (
		<Box>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Security Group and InActive Windows to Add:
			</Typography>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Group Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData?.groupName || ''}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={2} sx={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
					<Tooltip title="List of Security Group" variant="outlined">
						<Button
							variant='outlined'
							onClick={handleOpenGroupList}
							sx={{
                                mb:1,
								borderRadius:'4px',
								border:'1px solid black',
								color:'black'
							}}
						>
							<FormatListBulletedIcon/>
						</Button>
					</Tooltip>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Group STA:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData?.groupSta || ''}
							onChange={(event) => handleChange('groupSta', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={12}>
				    {isGroupListOpen ? (
						<Sheet
							className="OrderTableContainer"
							variant="outlined"
							sx={{
								minWidth: '0px',
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								minHeight: 0,
								border: '1px solid black',
								height:'270px',
								bgcolor:'#848284',
								mx:1,
								mt:2
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="xBetween"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									'--TableCell-height': '24px',
									'--TableCell-paddingX': '5px',
									'--TableCell-borderColor': '#939293',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{groupList.map((row) => (
										<tr
											key={row.id}
											onDoubleClick={handleOpenGroupList}
											onClick={() => setSelectedGroup(row)}
											style={{ 
												backgroundColor: props.selectedRow.id === row.id ? '#00cfd6' : 'white',
												color: props.selectedRow.id === row.id ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.name}</td>
											<td style={rowStyle}>{row.sta}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Sheet>
					):(
                        <FormControl sx={formControlStyle}>
                            <FormLabel sx={labelStyle}>
                                List of InActive Windows Permission for Selected Group:
                            </FormLabel>
                            <Box
                                sx={{
                                    border:'1px solid #d2dbe4',
                                    height:'250px',
                                    maxHeight:'250px',
                                    mx:1,
                                    borderRadius:'0px',
                                    bgcolor:'white',
									overflow:'auto'
                                }}
                            >
                                <Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px',
									}}
                                >
                                    {inActiveWindowsList.length == 0 ? (
                                        <Typography
                                            sx={{
                                                ml:1,
                                                fontSize:'14px',
                                                whiteSpace:'wrap'	
                                            }}
                                        >
                                            Not Found InActive Windows for Selected Sequrity Group!
                                        </Typography>
                                    ):(
										<tbody>
											{inActiveWindowsList.map((row) => (
												<tr
													key={row.id}
													onClick={() => setSelectedInActiveWindow(row)}
													onDoubleClick={() => handleSelectedInActiveWindow(row)}
													style={{ 
														backgroundColor: selectedInActiveWindow === row ? '#00cfd6' : 'white',
														color: selectedInActiveWindow === row ? 'white' : 'black',
													}}
												>
													<td style={rowStyle}>{row.id}</td>
													<td style={rowStyle}>{row.windowName}</td>
													<td style={rowStyle}>{row.moduleName}</td>
												</tr>
											))}
                                    	</tbody>
                                    )}
                                </Table>
                            </Box>
                        </FormControl>
					)}
				</Grid>
			</Grid>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Security of Window Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					onClick={handleAdd}
					//disabled={editedData.windowPermission !== '' && editedData.activate !== '' ? true : false}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Module Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData?.moduleName || ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Module Permission:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData?.modulePermission || ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Window Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData?.windowName || ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<Box
						sx={{
							mx:1
						}}
					>
						<Grid container spacing={0} sx={{ flexGrow: 1 }}>
							<Grid xs={5}>
								<Checkbox 
									label="Activate"
									variant="outlined"
									color='danger'
									defaultChecked 
									checked={editedData.activate == 'YES' ? true:false}
									onChange={(event) => handleChange('activate', event.target.checked ? 'YES' : 'NO')}
									sx={{
										color:'#840000',
										fontWeight:'bold'
									}}
								/>	
								<FormControl sx={formControlStyle}>
									<FormLabel sx={{...labelStyle, mx:0}}> 
										Permission:
									</FormLabel>
									<Box
										sx={{
											display:'flex',
											flexDirection:'column',
											height:'150px',
											overflow:'auto',
											p:1,
											mr:1,
											border:'1px solid black',
											bgcolor:'white'
										}}
									>
										{editedData.modulePermission == 'Full Control' && (
											<Checkbox 
												label='Full Control'
												color='neutral'
												variant='outlined'
												checked={editedData.windowPermission == 'Full Control'}
												onChange={(event) => handleChange('windowPermission', event.target.checked ? 'Full Control' : '')}
												sx={{
													mb:0.5,
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
											/>
										)}
										{editedData.modulePermission == 'Full Read' && (
											<Checkbox 
												label='Read Only'
												color='neutral'
												variant='outlined'
												checked={editedData.windowPermission == 'Read Only'}
												onChange={(event) => handleChange('windowPermission', event.target.checked ? 'Read Only' : '')}
												sx={{
													mb:0.5,
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
											/>
										)}
										{editedData.modulePermission == 'Share Control' && (
											<>
												<Checkbox 
													label='Full Control'
													color='neutral'
													variant='outlined'
													checked={editedData.windowPermission == 'Full Control'}
													onChange={(event) => handleChange('windowPermission', event.target.checked ? 'Full Control' : '')}
													sx={{
														mb:0.5,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
												/>
												<Checkbox 
													label='Modify Only'
													color='neutral'
													variant='outlined'
													checked={editedData.windowPermission == 'Modify Only'}
													onChange={(event) => handleChange('windowPermission', event.target.checked ? 'Modify Only' : '')}
													sx={{
														mb:0.5,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
												/>
												<Checkbox 
													label='Read Only'
													color='neutral'
													variant='outlined'
													checked={editedData.windowPermission == 'Read Only'}
													onChange={(event) => handleChange('windowPermission', event.target.checked ? 'Read Only' : '')}
													sx={{
														mb:0.5,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
												/>
											</>
										)}
										{/**
										{permissionsList && (permissionsList.map((row) => (
											<Radio 
												value={row.permission} 
												label={row.permission}
												checked={row.permission == editedData.windowPermission}
												onChange={(event) => handleChange('permission', event.target.value)}
												sx={{
													'& span': {
														borderRadius: '0px',
													}
												}}
											/>
										)))}
										*/}		
									</Box>
								</FormControl>
							</Grid>
							<Grid xs={7}>
								<FormControl sx={formControlStyle}>
									<FormLabel sx={labelStyle}> 
										Remarks:
									</FormLabel>
									<Textarea 
										maxRows={9}
										defaultValue={props.selectedRow.description}
										sx={{
											...inputStyle, 
											height:'200px',
											color:'#000078',
											lineHeight: '1.2'
										}}
										value={editedData?.remarks || ''}
										onChange={(event) => handleChange('remarks', event.target.value)}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
