import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.jsx';
import AdditionalInfoWnd from './Additional_Info_wnd.tsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../../URL.js';

 
export default function Security_Groups() {
	//#region Общие стили
	const inputStyle = {
		borderRadius: '0px', 
		minHeight: '0px', 
		height: '26px', 
		border: '1px solid black',
		color: '#000078', 
		fontWeight: 'bold',
		fontSize: '14px', 
		mb: 1, 
		width: '210px'
	};

	const lableStyle = {
		m: 0, 
		fontFamily: 'Arial, sans-serif', 
		fontSize: '16px'
	};

	const headerStyle = {
		width: 'auto',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight:0,
		color: 'black'
	};

	const engCategoryRowStyle = {
		width: 'auto',
		fontWeight: 'normal',
		fontSize: '16px',
		color: 'black',
		textAlign: 'left' as const,
		marginTop: -5
	};

	const rowStyle = {
		whiteSpace: 'nowrap', 
		width: 'auto',
		fontSize: '16px',
		lineHeight:0,
	};
	//#endregion

	//#region Стили отдельных элементов
	const mainContentTopBoxStyle = {
		px: 1,
		pb: 1,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		height: '85dvh',
		position: 'relative'
	};

	const mainContentBottomBoxStyle = {
		px: 1,
		pb: 1,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		height: '85dvh',
		gap: 1,
	};

	const engCategoriesBoxBoxStyle = {
		display: 'flex',
		flexDirection: 'column',
		height: '55px',
		overflow: 'auto',
		p: 0.5,
		mr: 1,
		border: '1px solid black',
		bgcolor: 'white'
	};

	const joyCheckBoxBoxStyle = {
		'--Checkbox-size':'14px',
		'& .css-r4cpcz-JoyCheckbox-checkbox': {
			borderRadius:0,
			'--joy-palette-neutral-outlinedBorder':'black',
		},
		'& .css-n110n' : {
			borderRadius:0,
			'--joy-palette-neutral-outlinedBorder':'black',
		},
	};

	const loginExternalFilterStyle = {
		...inputStyle,
		backgroundColor: '#c6ffff',
		"--Input-decoratorChildHeight": "24px"
	};

	const securityGroupExternalFilterStyle = {
		...inputStyle,
		"--Input-decoratorChildHeight": "24px"
	};

	const workspaceHeaderStyle = { 
		fontSize: '16px',
		fontWeight: 'bold',
		color: '#000078',
		fontFamily: 'Arial, sans-serif'
	};

	const searchAndFiltersBoxStyle = {
		display: 'flex',
		flexWrap: 'nowrap',
		gap: 1.5,
		alignItems: 'flex-end'
	};
	//#endregion

	//#region Стили таблицы
	const tableSheetStyle = {
		display: 'initial',
		width: '100%',
		borderRadius: '0px',
		flexShrink: 1,
		overflow: 'auto',
		minHeight: '645px',
		border: '1px solid black',
		bgcolor: '#848284'
	};

	const tableStyle = {
		'--TableCell-headBackground': '#d6d3ce',
		'--Table-headerUnderlineThickness': '1px',
		'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
		'--TableCell-paddingY': '1px',
		'--TableCell-height': '22px',
		'--TableCell-paddingX': '5px',
		'--TableCell-borderColor': '#939293',
		tableLayout: 'auto', 
		width: 'auto',
		borderRadius: '0px',
	};

	const filtersStyle = {
		position:'absolute',
		width:'100%',
		top:0,
		minWidth:0,
		minHeight:0,
		height: '100%',
		px: 0.5,
		m: 0,
		borderRadius: '0px',	
		"--Input-decoratorChildHeight": "24px",
	};
	//#endregion

	//#region Style Setters
	const setShowFiltersIconStyle = (showFiltersParam: boolean) => {
		return {
			fontSize: '20px',
			transition: '0.2s',
			transform: !showFiltersParam ? 'rotate(0deg)' : 'rotate(180deg)',
		};
	}
	//#endregion

	const headers = [
		{id:'name', name:'Name'}, 
		{id:'login', name:'User_ID'},
		{id:'userSTA', name:'User_STA'},
		{id:'code', name:'Code'},
		{id:'userGroup', name:'User_Group'},
		{id:'email', name:'E_mail'},
		{id:'phone', name:'Phone'},
		{id:'internalNumber', name:'Internal_Number'},
		{id:'skill', name:'Skill'},
		{id:'remarks', name:'Remarks'},
		{id:'licenceNum', name:'Licence_Num'},
		{id:'shift', name:'Shift'},
		{id:'inspector', name:'Inspector'},
		{id:'rii', name:'RII'},
		{id:'bms', name:'BMS'},
		{id:'storman', name:'Storeman'},
		{id:'reserveFromStore', name:'Reserve_From_Store'},
		{id:'purchaser', name:'Purchaser'},
		{id:'planningRestriction', name:'Planning_Restriction'},
		{id:'toolNum', name:'Tool_Num'},
		{id:'activate', name:'Activate'}
	];
	
	const [data, setData] = React.useState <{id:number, name:string, login:string, userSTA:string, code:string, userGroup:string, email:string, phone:string, internalNumber:string, skill:string, remarks:string, licenceNum:string, shift:boolean, inspector:boolean, rii:boolean, bms:boolean, storeman:boolean, reserveFromStore:boolean, purchaser:boolean, planningRestriction:boolean, toolNum:string, activate:string, active: string, securityGroup:string, engCategory:string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, name:string, login:string, userSTA:string, code:string, userGroup:string, email:string, phone:string, internalNumber:string, skill:string, remarks:string, licenceNum:string, shift:boolean, inspector:boolean, rii:boolean, bms:boolean, storeman:boolean, reserveFromStore:boolean, purchaser:boolean, planningRestriction:boolean, toolNum:string, activate:string, active: string, securityGroup:string, engCategory:string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({login:'', name:'', code:'', userGroup:'', securityGroup:'', userSTA:'', skill:'', remarks:'', licenceNum:'', toolNum:'', activate:'Y', email:'', phone:'', internalNumber:'', sta:'', ac:'',category:[]});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [groups, setGroups] = React.useState ([{}]);
	const [sta, setSta] = React.useState ([{}]);
	const [skills, setSkills] = React.useState ([{}]);
	const [engCategories, setEngCategories] = React.useState <{id:number,category:string}[]>([]);
	const [showEditor, setShowEditor] = React.useState (true);
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	const [reportLoading, setReportLoading] = React.useState(false);
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Users/View(categories=[])`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			const sortedResult =result.value.sort((a, b) => b.id - a.id);
			setData(sortedResult);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка groups
	const fetchGroups = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSUserGroups?$select=userGroup`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setGroups(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка sta
	const fetchSta = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations?$select=code`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setSta(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка skills
	const fetchSkills = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/UserSkills?$select=id,skill,description`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setSkills(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка engineering categories
	const fetchCategories= async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineeringCategories?$select=id,category`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setEngCategories(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		fetchData();
		fetchGroups();
		fetchSta();
		fetchSkills();
		fetchCategories()
	}, []);
	
	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
        for (const key in filters) {
			if(key !== 'activate' && key !=='category' && filters[key] !== ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
				
			} else {
				if (filters.activate === 'Y') {filtered = filtered.filter(row => row.activate == filters.activate)}
				if (filters.category.length !== 0) {filtered = filtered.filter(row => filters.category.includes(row.engCategory));}
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({login:'', name:'', code:'', userGroup:'', securityGroup:'', userSTA:'', skill:'', remarks:'', licenceNum:'', toolNum:'', activate:'Y', email:'', phone:'', internalNumber:'', active:'', sta:'', ac:'', category:[]});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		if(property === 'category'){
			if(filters.category.includes(value)){
				const index = filters.category.indexOf(value);
				if (index !== -1) {
					const categories = filters.category.filter(element => element !== value);
					setFilters({ ...filters, [property]: categories });
				}	
			} else {
				let categories = filters.category
				categories.push(value)
				setFilters({ ...filters, [property]: categories });
			}	
		} else {
			setFilters({ ...filters, [property]: value });
		}	
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id:null, login:'', name:'', code:'', userGroup:'', securityGroup:'', userSTA:'', skill:'', remarks:'', licenceNum:'', toolNum:'', activate:'Y', email:'', phone:'', internalNumber:'', sta:'', ac:''};
		return Object.keys(initialState).every(key => {
			if (key === 'id') {
				return filters[key] === null || filters[key] === undefined || filters[key] === '';
			}
			return filters[key] === initialState[key];
		});
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') {
			event.preventDefault();  
			if (filters[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};
	
	//Создание Excel отчёта
	const createExcel = () => {
		const categories = engCategories.filter(item => filters.category.includes(item.category)).map(item => item.id);
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Users/Xlsx(categories=[${categories}])?login=${filters.login}&name=${filters.name}&code=${filters.code}&userGroup=${filters.userGroup}&licence=${filters.licenceNum}&ac=${filters.ac}&secGroup=${filters.securityGroup}&skill=${filters.skill}&activate=${filters.activate === 'Y'?true:false}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Security_Users.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	const createReport = () => {
		setReportLoading(true);
		let db = 'PostgreSQL'
		if (URL.includes('devpg')){
			db = 'PostgreSQL'
		} else {
			db = 'SqlServer'
		}
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": `Templates/${db}/Admin/UserRegistrationList.frx`,
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};

	const rowRefs = React.useRef([]);

	const handleFocusRow = () => {
        const selectedRowIndex = filteredData.findIndex(row => row.id === selectedRow.id);
        if (selectedRowIndex !== -1 && rowRefs.current[selectedRowIndex]) {
            rowRefs.current[selectedRowIndex].focus();
        }
    };

	return (
		<React.Fragment>
			{additionalInfo.open && (
				<AdditionalInfoWnd item={additionalInfo.item} value={filters[additionalInfo.item]} setAdditionalInfo={setAdditionalInfo}/>
			)}
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '70% 30%' : '1rf',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={mainContentTopBoxStyle}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0,
							mt:0.5
						}}
					>
						<Box>
							<Tooltip 
								title='View Report'
								sx={{ position:'relative' }}
							
							>
								<IconButton 
									variant='plain'
									onClick={createReport}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px' 
										src="images/svg/report.svg" 
										alt="" 
									/>
									{reportLoading && (
										<CircularProgress
											size={36}
											sx={{
												position: 'absolute',
												top: 0,
												left: 3.5,
												zIndex: 5,
											}}
										/>
									)}
								</IconButton>
							</Tooltip>
							<Tooltip title='Create Excel Report'>
								<IconButton
									variant='plain'
									onClick={createExcel}
									sx={{
										m:0,
									}}
								>
									<img 
										width='35px' 
										src="images/svg/excel.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Reset Filters'>
								<IconButton
									variant='plain'
									onClick={refreshFilters}
									sx={{
										m:0,
									}}
								>
									<img 
										width='35px' 
										src="images/svg/refresh.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
								<IconButton
									variant='plain'
									onClick={toggleEditore} 
									sx={{
										m:0,
										transition: '0.3s',
										transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
									}}
								>
									<img 
										width='35px' 
										src="images/svg/arrowBack.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
						</Box>
						<FormControl>
							<FormLabel sx={lableStyle}> 
								Eng. Category:
							</FormLabel>
							<Box
								sx={engCategoriesBoxBoxStyle}
							>
								{ engCategories.map((row) => (
									<th style={engCategoryRowStyle}>
										<Checkbox
											size="sm" 
											variant="outlined"
											color="neutral"
											label={row.category}
											value={row.category}
											sx={joyCheckBoxBoxStyle} 
											onChange={(event) => handleChange('category', event.target.value)}
										/>
									</th>
								))}
							</Box>
						</FormControl>
					</Box>	
					<Typography 
						sx={workspaceHeaderStyle}
					>
						Users Registration List:
					</Typography>			
					<Box
						className="SearchAndFilters-tabletUp"
						sx={searchAndFiltersBoxStyle}
					>
						<FormControl>
							<FormLabel sx={lableStyle}>User ID:</FormLabel>
							<Autocomplete
								options={[...new Set(data.map(data => data.login))].filter(row => row).map(login => ({ label: login }))}
								sx={loginExternalFilterStyle}
								onChange={(event, value) => handleChange('login', value ? value.label : '')}
								onKeyDown={(event) => handleAdditionalInfo(event, 'login')}
								value={{label: filters.login || ''}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>User Name:</FormLabel>
							<Input
								value={filters.name}
								onChange={(event) => handleChange('name', event.target.value)}
								sx={inputStyle}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Sec. Group:</FormLabel>
							<Autocomplete
								options={[...new Set(data.map(data => data.securityGroup))].filter(row => row).map(securityGroup => ({ label: securityGroup }))}
								sx={securityGroupExternalFilterStyle}
								onChange={(event, value) => handleChange('securityGroup', value ? value.label : '')}
								value={{label: filters.securityGroup || ''}}
							/>		
						</FormControl>
						<Checkbox 
							variant="outlined"
							color="neutral" 
							label="Active Only"
							checked={filters.activate == 'Y' ? true:false}
							onChange={(event) => handleChange('activate', event.target.checked ? 'Y' : 'N')}
							sx={{...joyCheckBoxBoxStyle, mb:1}}
						/>
					</Box>
					<Box
						className="SearchAndFilters-tabletUp"
						sx={searchAndFiltersBoxStyle}
					>
						<FormControl>
							<FormLabel sx={lableStyle}>Code:</FormLabel>
							<Input
								value={filters.code}
								onChange={(event) => handleChange('code', event.target.value)}
								sx={{...inputStyle,width:'120px'}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Group:</FormLabel>
							<Input
								value={filters.userGroup}
								onChange={(event) => handleChange('userGroup', event.target.value)}
								sx={{...inputStyle,width:'120px'}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Lic.:</FormLabel>
							<Input
								value={filters.licenceNum}
								onChange={(event) => handleChange('licenceNum', event.target.value)}
								sx={{...inputStyle,width:'120px'}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Skill:</FormLabel>
							<Input
								value={filters.skill}
								onChange={(event) => handleChange('skill', event.target.value)}
								sx={{...inputStyle,width:'120px'}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>AC:</FormLabel>
							<Input
								value={filters.ac}
								onChange={(event) => handleChange('ac', event.target.value)}
								sx={{...inputStyle,width:'120px'}}
							/>
						</FormControl>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor:checkFilters()?'transparent':'#DDE7EE'
						}}
					>
						<ArrowDropDownIcon 
							sx={setShowFiltersIconStyle(showFilters)}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={tableSheetStyle}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={tableStyle}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters.id? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.name}
												onChange={(event) => handleChange('name', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.login))].filter(row => row).map(login => ({ label: login }))}
												onChange={(event, value) => handleChange('login', value ? value.label : '')}
												value={{label: filters.login || ''}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.userSTA))].filter(row => row).map(userSTA => ({ label: userSTA}))}
												onChange={(event, value) => handleChange('userSTA', value ? value.label : '')}
												value={{label: filters.userSTA || ''}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.code}
												onChange={(event) => handleChange('code', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.userGroup))].filter(row => row).map(userGroup => ({ label: userGroup}))}
												onChange={(event, value) => handleChange('userGroup', value ? value.label : '')}
												value={{label: filters.userGroup || ''}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.email}
												onChange={(event) => handleChange('email', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.phone}
												onChange={(event) => handleChange('phone', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.internalNumber}
												onChange={(event) => handleChange('internalNumber', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.skill}
												onChange={(event) => handleChange('skill', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.licenceNum}
												onChange={(event) => handleChange('licenceNum', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{padding:0}}>
											<Input
												value={filters.toolNum}
												onChange={(event) => handleChange('toolNum', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#00cfd6': index % 2 === 0 ? '#DDE7EE' : 'white',
											color: selectedRow.id === row.id ? 'white' : 'black',
										}}
										ref={el => (rowRefs.current[index] = el)}
                            			tabIndex={0}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.name}</td>
										<td style={rowStyle}>{row.login}</td>
										<td style={rowStyle}>{row.userSTA}</td>
										<td style={rowStyle}>{row.code}</td>
										<td style={rowStyle}>{row.userGroup}</td>
										<td style={rowStyle}>{row.email}</td>
										<td style={rowStyle}>{row.phone}</td>
										<td style={rowStyle}>{row.internalNumber}</td>
										<td style={rowStyle}>{row.skill}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.licenceNum}</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.shift}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.inspector}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.rii}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.bms}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.storeman}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.reserveFromStore}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.purchaser}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.planningRestriction}
												sx={joyCheckBoxBoxStyle}
											/>
										</td>
										<td style={rowStyle}>{row.toolNum}</td>
										<td style={rowStyle}>{row.activate}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							fontSize:'12px'
						}}
					>
						Found {filteredData.length} Records
					</Box>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={mainContentBottomBoxStyle}
					>
						<AddEditeForm
							selectedRow={selectedRow} 
							setSelectedRow={setSelectedRow}
							reloadTable={fetchData}
							focusRow={handleFocusRow}
							setResponseStatus={setResponseStatus}
							groups={groups}
							sta={sta}
							skills={skills}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
