import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import MaintenanceAddEditeForm from './MaintenancePlanEditore.tsx';
import {ComponentTree} from '../../../../../../Test/story-test/TreeCustom.tsx'

import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../../URL.js';

export default function ATA() {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1, minWidth:0,width:'100%'}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'14px',alignSelf:'center',lineHeight:1, color:'black'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[{id:'ataCode',name:'ATA'}, {id:'title',name:'Title'},{id:'acFamily',name:'AC_Family'},{id:'etopsSignificant',name:'ETOPS_Significant'}]

	const [data, setData] = React.useState<{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, ataCode:'', title:'', acFamily:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [acFamilies, setAcFamilies] = React.useState ([{}]);
	const [showEditor, setShowEditor] = React.useState (true);
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка AC Families
	const fetchFamilies = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftFamilies?$select=acfamily`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setAcFamilies(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		fetchData();
		fetchFamilies();
	}, []);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, ataCode:'', title:'', acFamily:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id: 0, ataCode: '', title: '', acFamily: '' };
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Atas/Xlsx()?$filter=contains(atacode, '${filters.ataCode}') and contains(title, '${filters.title}') and contains(acfamily, '${filters.acFamily}')`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'ATA.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}
	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?'auto 660px':'100%',
				}}
			>
				<Box
					id='tablesArea'
					sx={{
						display: 'grid',
						gridTemplateRows:'30% 70%',
						position:'relative'
					}}
				>
					<Box
						id='topArea'
						sx={{
							position:'relative',
						}}
					>
						<Typography 
							id='topAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							Part Effectivity, Maintenance Plan:
						</Typography>	
						<Box
							id='buttons'
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								position:'absolute',
								right:16,
								height:40
							}}
						>
							<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
								<IconButton
									id='editoreButton'
									variant='plain'
									onClick={toggleEditore} 
									sx={{
										m:0,
										transition: '0.3s',
										transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
									}}
								>
									<img 
										width='35px' 
										src="images/svg/arrowBack.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
						</Box>		
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 54px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'44px',
								mb:'10px'
							}}
						>
							<ComponentTree/>
						</Sheet>
					</Box>

					<Box
						id='bottomArea'
						sx={{
							position:'relative',
						}}
					>
						<Typography 
							id='bottomAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							Positions:
						</Typography>	
						<Box
							id='buttons'
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								position:'absolute',
								right:16,
								height:40,
								width:`100%`,
							}}
						>
							<Box
								className="SearchAndFilters-tabletUp"
								sx={{
									width:'100%',
									display:'grid',
									gridTemplateColumns:'auto 100px repeat(3, 20%)',
									gap:1
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems:'flex-end',
										justifyContent:'center',
										gridColumn:2,
										mb:1
									}}
								>
									<Typography sx={{...lableStyle,alignSelf:'flex-end'}}>
										Sub-Assy:
									</Typography>
									<Checkbox
										sx={{
											ml:1,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
									/>
								</Box>
								<FormControl>
									<FormLabel sx={lableStyle}>IPS Pos.:</FormLabel>
									<Autocomplete
										options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
										sx={inputStyle}
										onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
										value={!filters.acFamily ? null : { label: filters.acFamily}}
									/>
								</FormControl>
								<FormControl>
									<FormLabel sx={lableStyle}>Part Eff.:</FormLabel>
									<Input
										value={filters.ataCode}
										onChange={(event) => handleChange('ataCode', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
								<FormControl>
									<FormLabel sx={lableStyle}>Description:</FormLabel>
									<Input
										value={filters.title}
										onChange={(event) => handleChange('title', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
							</Box>
						</Box>		
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 54px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'44px',
								mb:'10px'
							}}
						>
							<ComponentTree/>
						</Sheet>
					</Box>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<MaintenanceAddEditeForm
						selectedRow={selectedRow} 
						reloadTable={fetchData}
						setResponseStatus={setResponseStatus}
						acFamilies={acFamilies}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
