import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import AdditionalInfoWnd from './MaintenancePlan/Additional_Info_wnd';

import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../../URL.js';

export default function AddEditeForm(props) {
	//Стили
	const filtersInputStyle = { borderRadius: 0, px:1, mx: 1, height: 26, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const filtersLabelStyle = { mx: 1, my: 0, p: 0, fontSize:'12px', alignSelf:'center' };
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px', alignSelf:'center' };
	const formControlStyle = { my: 0.5 };
	
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	const [editedData, setEditedData] = React.useState({});
	const [isTypeValid, setIsTypeValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsTypeValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности ATA
	const checkValidType = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/ValidType()?type='${editedData.type}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsTypeValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') { 
			event.preventDefault(); 
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = async () => {
		if (await checkValidType()) {
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						manufacturer:editedData.family ? props.engineFamilies.find(data => data.family === editedData.family)?.manufacturer : ''
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.type === props.selectedRow.type || await checkValidType()){
			setIsTypeValid(true);
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						manufacturer:editedData.family ? props.engineFamilies.find(data => data.family === editedData.family)?.manufacturer : '',
						numOfFanBlades:props.selectedRow.numOfFanBlades
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}

	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	

		setEditedData({ ...editedData, [property]: value });
	};
	
	return (
		<Box
			id='editorArea'
			sx={{
				position:'absolute',
				height:'100%',
				width:'100%',
				display:'grid',
				gridTemplateRows:'30% 70%'
			}}
		>
			<Box
				sx={{
					position:'relative',
					height:`100%`,
					width:'100%',
				}}
			>
				<Typography 
					sx={{
						fontSize:'18px',
						fontWeight:'bold',
						color:'#000078',
						fontFamily:'Arial, sans-serif',
						ml:1
					}}
				>
					Part Effectivity Editor (for Selected IPC Position):
				</Typography>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={editedData.family && editedData.mfrCode ? false : true}
						onClick={handleAdd}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow.id && editedData.family && editedData.mfrCode ? false : true}
						onClick={handleUpdate}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow.id ? false : true}
						onClick={handleDelete}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>

				<Box
					sx={{
						position:'absolute',
						height:`calc(100% - 80px)`,
						overflow:'auto'
					}}
				>
					<Grid container spacing={0} sx={{ flexGrow: 1 }}>
						<Grid xs={5}>
							<FormControl sx={formControlStyle} required>
								<FormLabel sx={labelStyle}>PN:</FormLabel>
								<Autocomplete
									disableClearable
									options={[]}
									//value={{ label: editedData?.mfrCode ?? ''}}
									sx={{
										...inputStyle,
										backgroundColor:'#c6ffff',
									}}
									//onChange={(event, value) => handleChange('mfrCode', value ? value.label : '')}
									//onKeyDown={(event) => handleAdditionalInfo(event, 'mfrCode')}
								/>
							</FormControl>
						</Grid>
						<Grid xs={7}>
							<FormControl sx={formControlStyle} required>
								<FormLabel sx={labelStyle}>Description:</FormLabel>
								<Input 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									//value={editedData.mfrCode? manufacturer.name : ''}
								/>
							</FormControl>
						</Grid>

						<Grid xs={3} sx={{mt:2}}>
							<Box
								sx={{
									display: 'flex',
								}}
							>
								<Typography sx={labelStyle}>
									Preferable:
								</Typography>
								<Checkbox
									sx={{
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
								/>
							</Box>
						</Grid>
						<Grid xs={3}/>
						<Grid xs={6} sx={{mt:2,display:'flex', justifyContent:'flex-end'}}>
							<Button		
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									mx:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Part Catalog
							</Button>
							<Button		
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									mx:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Refresh PN
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box
				sx={{
					position:'relative',
					height:`100%`,
					width:'100%',
				}}
			>
				<Typography 
					sx={{
						fontSize:'18px',
						fontWeight:'bold',
						color:'#000078',
						fontFamily:'Arial, sans-serif',
						ml:1,
						
					}}
				>
					Position Editor:
				</Typography>
				<Box
					sx={{
						position:'absolute',
						height:`calc(100% - 34px)`,
						width:'100%',
						overflow:'auto'
					}}
				>
					<Grid container spacing={0} sx={{ flexGrow: 1 }}>
						<Grid xs={12} sx={{m:1,}}>
							<Box
								sx={{
									width:'100%',
									height:150,
									border:'1px solid gray',
									position:'relative',
								}}
							>
								<Typography 
									sx={{
										position:'absolute',
										top:'-7px',
										fontSize:12,
										fontWeight:800,
										color:'black',
										bgcolor:'#d6d3ce',
										lineHeight:1,
										ml:1
									}}
								>
									Select PN, Description
								</Typography>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={6}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={filtersLabelStyle}>Filter PN:</FormLabel>
											<Input 
												sx={{
													...filtersInputStyle, 
													backgroundColor:'#c6ffff',
												}} 
												value={editedData.family ? props.engineFamilies.find(data => data.family === editedData.family)?.manufacturer : ''}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={filtersLabelStyle}>Filter Description:</FormLabel>
											<Input 
												sx={{
													...filtersInputStyle, 
													
												}} 
												value={editedData.family ? props.engineFamilies.find(data => data.family === editedData.family)?.manufacturer : ''}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={filtersLabelStyle}>Engine Family:</FormLabel>
											<Autocomplete
												disableClearable
												options={[]}
												value={{ label: editedData?.family ?? ''}}
												onChange={(event, value) => handleChange('family', value ? value.label : '')}
												onKeyDown={(event) => handleAdditionalInfo(event, 'family')}
												sx={{
													...filtersInputStyle,
													backgroundColor:'#c6ffff',
												}}
											/>
										</FormControl>
									</Grid>
									<Grid xs={12}>
										<Box
											sx={{
												border:'1px solid #d2dbe4',
												height:'80px',
												mx:1,
												borderRadius:'0px',
												bgcolor:'white',
												overflow:'auto'
											}}
										>
											<Table
												aria-labelledby="tableTitle"
												borderAxis="none"
												stickyHeader
												hoverRow
												sx={{
													'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
													'--TableCell-paddingY': '1px',
													"--TableCell-height": "24px",
													'--TableCell-paddingX': '5px',
													tableLayout: 'auto', 
													width:'auto',
													borderRadius:'0px'
												}}
											>
												<tbody>
													{/** 
													{moduleList.map((row) => (
														<tr
															key={row.id}
															onClick={() => setSelectedGroupForModule(row)}
															style={{ 
																backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
																color: selectedGroupForModule === row ? 'white' : 'black',
															}}
														>
															<td style={rowStyle}>{row.id}</td>
															<td style={rowStyle}>{row.groupName}</td>
															<td style={rowStyle}>{row.groupSta}</td>
															<td style={rowStyle}>{row.permission}</td>
															<td style={rowStyle}>{row.activate}</td>
															<td style={rowStyle}>{row.remarks}</td>
														</tr>
													))}
														*/}
												</tbody>
											</Table>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid xs={12}>
							<Tooltip title='Add'>
								<IconButton
									variant='plain'
									disabled={editedData.family && editedData.mfrCode ? false : true}
									onClick={handleAdd}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px' 
										src="images/svg/add.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Update'>
								<IconButton 
									variant='plain'
									disabled={props.selectedRow.id && editedData.family && editedData.mfrCode ? false : true}
									onClick={handleUpdate}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px' 
										src="images/svg/save.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Delete'>
								<IconButton 
									variant='plain'
									disabled={props.selectedRow.id ? false : true}
									onClick={handleDelete}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px'  
										src="images/svg/trashcan.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid xs={7}>
							<FormControl required>
								<FormLabel sx={labelStyle}>Position PN:</FormLabel>
								<Input 
									size="sm" 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									//value={editedData.mfrCode? manufacturer.name : ''}
								/>
							</FormControl>
						</Grid>
						<Grid xs={5}>
							<FormControl>
								<FormLabel sx={labelStyle}>FIN:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									//value={editedData.mfrCode? manufacturer.name : ''}
								/>
							</FormControl>
						</Grid>
						<Grid xs={6}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>IPC Position:</FormLabel>
								<Box
									sx={{
										display:'flex'
									}}
								>
									<Input 
										sx={{
											...inputStyle, 
										}} 
										//value={editedData.mfrCode? manufacturer.name : ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										//value={editedData.mfrCode? manufacturer.name : ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										//value={editedData.mfrCode? manufacturer.name : ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										//value={editedData.mfrCode? manufacturer.name : ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										//value={editedData.mfrCode? manufacturer.name : ''}
									/>
								</Box>
							</FormControl>
						</Grid>
						<Grid xs={6}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>I/R AMM Reference:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									//value={editedData.mfrCode? manufacturer.name : ''}
								/>
							</FormControl>
						</Grid>
						
						<Grid xs={9}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Position Description:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									//value={editedData.mfrCode? manufacturer.name : ''}
								/>
							</FormControl>
						</Grid>
						<Grid xs={3}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Position:</FormLabel>
								<Autocomplete
									options={[]}
									value={{ label: editedData?.family ?? ''}}
									onChange={(event, value) => handleChange('family', value ? value.label : '')}
									onKeyDown={(event) => handleAdditionalInfo(event, 'family')}
									sx={{
										...inputStyle,
									}}
								/>
							</FormControl>
						</Grid>
						<Grid xs={12}>
							<Box sx={{
								display: 'flex',
								alignItems:'center',
								justifyContent: 'space-between',
								mx:1
							}}>
								<Tooltip title='Position is Engine (Power Plant) Part Number'>
									<Checkbox 
										label="is PP:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is APU Part Number'>
									<Checkbox 
										label="is APU:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is Main Landing Gear Part Number'>
									<Checkbox 
										label="is MLG:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is Nose Landing Gear Part Number'>
									<Checkbox 
										label="is NLG:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is Propeller Part Number'>
									<Checkbox 
										label="is PROP:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is Main GearBox Part Number'>
									<Checkbox 
										label="is MGBX:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is Tail GearBox Part Number'>
									<Checkbox 
										label="is TGBX:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								<Tooltip title='Position is Structure Part Number'>
									<Checkbox 
										label="is Strct:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
								
							</Box>
						</Grid>
						<Grid xs={12}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>
									Remarks:
								</FormLabel>
								<Textarea
									maxRows={10}
									value={editedData?.description || ''}
									sx={{
										...inputStyle, 
										height: '70px', 
										lineHeight: '1.2'
									}}
									onChange={(event) => handleChange('description', event.target.value)}
								>
								</Textarea>
							</FormControl>
						</Grid>

						<Grid xs={2}></Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since New'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSN:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since New'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Overhaul'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSO:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Overhaul'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Installation'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSI:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Installation'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Repair (Restoration)'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSR:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Repair (Restoration)'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Time'>
								<Typography sx={{color:'black',fontSize:16}}>
									TAPU:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Time'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						
						<Grid xs={2}></Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since New'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSN:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since New'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Overhaul'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSO:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Overhaul'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Installation'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSI:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Installation'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Repair (Restoration)'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSR:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Repair (Restoration)'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Cycle'>
								<Typography sx={{color:'black',fontSize:16}}>
									CAPU:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Cycle'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
					</Grid>

				</Box>
			</Box>
		</Box>
	);
}