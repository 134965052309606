import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import AdditionalInfoWnd from './Additional_Info_wnd.tsx';

import URL from '../../../../../../URL'

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px' };
	const formControlStyle = { my: 1 };

	const [engineModels, setEngineModels] = React.useState([]);
	const [apuModels, setApuModels] = React.useState([]);
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	const [editedData, setEditedData] = React.useState({});
	const [isSNValid, setIsSNValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsSNValid(true);
	}, [props.selectedRow]);

	//Запрос списка EngineModels
	React.useEffect(() => {
		const fetchEngineModels = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels?$filter=type eq '${editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.engineType : ''}' and apu eq 'N'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setEngineModels(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchEngineModels();
	}, [editedData.acModel]);

	//Запрос списка ApuModels
	React.useEffect(() => {
		const fetchApuModels = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels?$filter=type eq '${editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.apuType : ''}' and apu eq 'Y'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setApuModels(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchApuModels();
	}, [editedData.acModel]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности SerialNumber
	const checkValidSN = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/ValidSerialNumber()?serial='${editedData.serialNumber}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsSNValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') { 
			event.preventDefault(); 
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	//Создание PDF отчёта
	{/** 
	const createReport = () => {
		setReportLoading(true);
		return fetch(`${Base_URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserEditor",
				"isExportAsPicture": false,
				"parameters": [
					{
					"name": 'userId',
					"value": `${props.selectedRow.login}`
					}
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', '/UserEditor.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}
	//Обработчик события добавления новой записи
	const handleAdd = async () => {
		if (await checkValidSN()) {
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						engineType:editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.engineType : '',
						apuType:editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.apuType : ''
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.serialNumber === props.selectedRow.serialNumber || await checkValidSN()){
			setIsSNValid(true);
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						mfrDate:editedData.mfrDate
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	

		if (property === 'acType') {
			setEditedData({ ...editedData, [property]: value, 'acModel':'' });
		} else {
			setEditedData({ ...editedData, [property]: value });
		}	
	};

	return (
		<Box>
			{additionalInfo.open && (
				<AdditionalInfoWnd item={additionalInfo.item} value={editedData[additionalInfo.item]} setAdditionalInfo={setAdditionalInfo}/>
			)}
			<Typography 
				sx={{
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Initial Registration Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.acType && editedData.acModel && editedData.mfrDate && editedData.serialNumber && editedData.engineModel ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.acType && editedData.acModel && editedData.mfrDate && editedData.serialNumber && editedData.engineModel ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={12} sx={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
					<Checkbox 
						label="Activate"
						color='danger'
						variant='outlined' 
						sx={{
							color:'#840000',
							m:0.5
						}}
						checked={editedData.active == 'Y' ? true : false}
						onChange={(event, value) => handleChange('active', event.target.checked ? 'Y' : 'N')}
					/>
				</Grid>

				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Aircraft Type:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.acTypes.map(acType => acType.type).filter((type, index, self) => self.indexOf(type) === index).map(type => ({label:type}))}
							value={{ label: editedData.acType ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('acType', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'acType')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Aircraft Model:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.acTypes.filter(acType => acType.type === editedData.acType).map(acType => acType.model).filter((model, index, self) => self.indexOf(model) === index).map(model => ({label:model}))}
							value={{ label: editedData.acModel ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('acModel', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'acModel')}
						/>
					</FormControl>
				</Grid>
			
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC Version:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.acVersion ?? ''}
							onChange={(event) => handleChange('acVersion', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>MFR Date:</FormLabel>
						<Input
							type="date"
							sx={inputStyle}
							value={editedData.mfrDate?.slice(0, 10) ?? ''}  
							onChange={(event) => handleChange('mfrDate', event.target.value)}
						/>
					</FormControl>
				</Grid>
				
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={{ ...labelStyle, width: '100%', display: 'flex' }}>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								<Typography>AC Serial No:</Typography>
								<Typography sx={{ color: '#C41C1C', fontSize: '1em', marginLeft: '4px' }}>*</Typography>
							</span>
							<Typography 
								color='danger'
								sx={{ 
									ml: 'auto', 
									mr:2,
									fontSize:10,
									display:isSNValid === false? 'flex' :'none'
								}}
							>
								SN is already exist
							</Typography>
						</FormLabel>
						<Input 
							color={!isSNValid ? 'danger' : 'primary'}
							sx={{...inputStyle, border:isSNValid ? '1px solid black':'1px solid #C41C1C', color: isSNValid ? '#000084':'#C41C1C'}} 
							value={editedData?.serialNumber ?? ''} 
							onChange={(event) => handleChange('serialNumber', event.target.value)} 
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC Line No:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.lineNo ?? ''}
							onChange={(event) => handleChange('lineNo', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC Var No:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.reg ?? ''}
							onChange={(event) => handleChange('varNo', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC Eff. No:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.effectNo ?? ''}
							onChange={(event) => handleChange('effectNo', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC IPC No:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.ipcNo ?? ''}
							onChange={(event) => handleChange('ipcNo', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Airframe:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.airframe ?? ''}
							onChange={(event) => handleChange('airframe', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Engine Type:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.engineType : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>APU Type:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.apuType : ''}
						/>
					</FormControl>
				</Grid>
				
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Engine Model:</FormLabel>
						<Autocomplete
							disableClearable
							options={engineModels.map(data=> ({ label: data.model }))}
							value={{ label: editedData.engineModel ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('engineModel', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'engineModel')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>APU Model:</FormLabel>
						<Autocomplete
							disableClearable
							options={apuModels.map(data=> ({ label: data.model }))}
							value={{ label: editedData.apuModel ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('apuModel', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'apuModel')}
						/>
					</FormControl>
				</Grid>
		
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Engine Oil Alert:</FormLabel>
						<Input  
							sx={inputStyle} 
							type="number"
							slotProps={{
								input: {
									step: 0.1,
									min:0
								}
							}}
							value={editedData?.engineOilAlert ?? ''}
							onChange={(event) => handleChange('engineOilAlert', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>APU Oil Alert:</FormLabel>
						<Input  
							sx={inputStyle} 
							type="number"
							slotProps={{
								input: {
									step: 0.1,
									min:0
								}
							}}
							value={editedData?.apuOilAlert ?? ''}
							onChange={(event) => handleChange('apuOilAlert', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Remarks:</FormLabel>
						<Textarea 
							maxRows={5}
							sx={{
								...inputStyle,
								color:'#000078',
								width:'100%', 
								height:'150px',
								lineHeight:1.2
							}}
							value={editedData?.remarks ?? ''}
							onChange={(event) => handleChange('remarks', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
