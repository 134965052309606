import React from 'react'
import { useTreeState } from '../helpers/hooks.ts'
import Tree from '../index.tsx'

export let smallData = 
[
    {
        id: 10,
        image: '1.png',
        description: {id: 'RA-00005'}
    },
    {
        id: 1,
        image: '2.png',
        description: {id: 'Work Packages:'},
        children: [
            {
                id: 2,
                image: 'box_2.svg',
                description: {id: 273895, WP: 'WP240010-2', SB: 'SB737-27-213412', MNHR: 'MNHR:4.25', TAKEOFF: 'TAKEOFF ', Remarks: 'TEST'},
                children: [
                    {
                        id: 5,
                        image: 'box_1.svg',
                        description: {id: 1473473, WO: 'WO23-01', SB: 'SB1212-121', NUM: '8745874587', Remarks: 'ABC MMM DDD Qwerty Hello Test Test'},
                        children: [ ]
                    },
                    {
                        id: 6,
                        image: 'box_1h.svg',
                        description: {id: 4646464, WO: 'WO2323233-01', SB: 'SB124323-121', NUM: '8745874587', Remarks: 'ABC MMM DDD Qwerty Hello Test Test'}
                    },
                    {
                        id: 7,
                        image: 'box_1.svg',
                        description: {id: 3473473, WO: 'WO23786867-01', SB: 'SB121432-121', NUM: '8745874587', Remarks: 'ABC MMM DDD Qwerty Hello Test Test'}
                    }
                ]
            },
            {
                id: 3,
                image: 'box_2h.svg',
                description: {id: 84339, WP: 'WP250110-2', SB: 'SB737-21-213412', MNHR: 'MNHR:4.25', TAKEOFF: 'TAKEOFF ', Remarks: 'TEST'},
                children : [
                    {
                        id: 4,
                        image: 'box_1h.svg',
                        description: {id: 12312312, WO: 'WO2323233-01', SB: 'SB124323-121', NUM: '8745874587', Remarks: 'ABC'}
                    }
                ]
            }
        ]
    }
]
  
export const ComponentTree = () => {
    const { required, handlers } = useTreeState({
      id: 'tree',
      data: smallData,
      defaultOpened: true,
      multipleSelect: false,
    })
    
    return (
      	<Tree
			{...required}
			{...handlers}
			gapMode="padding" 
			depthGap={20}
		/>
    )
}