import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import { CssVarsProvider } from '@mui/joy/styles';

import CircularProgress from '@mui/material/CircularProgress';
import Textarea from '@mui/joy/Textarea';

import ComponentsTable from './Find_Components_Table.tsx';
import StockTable from './Find_Stock_Table.tsx';
import ErrorSuccessfulResponse from '../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../URL.js';

export default function Find_Components () { 
	const inputStyle = { borderRadius: 0, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px',"--Input-decoratorChildHeight": "24px" };
	const labelStyle = { alignSelf:'center', my: 0, p: 0, fontSize:'14px', lineHeight:0.9 };
	const formControlStyle = { my: 0 };

	const [inStock,setInStock] = React.useState(false);
	const [filters,setFilters] = React.useState({AcReg:'',IpcPos:'',Pn:'',SerialNumber:'',Description:''});
	const [filtersInStock,setFiltersInStock] = React.useState({Stock:'',Pn:'',SerialNumber:'',Description:''});
	const [stockComponents, setStockComponents] = React.useState([{}]);
	const [showComponent, setShowComponents] = React.useState(false);
	const [components, setComponents] = React.useState([{}]);
	const [showStockComponent, setShowStockComponents] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})
	//Визуализация
	const [hasMore, setHasMore] = React.useState(true);
	const itemsPerPage = 20;
	const [records, setRecords] = React.useState(itemsPerPage);

	const loadMore = () => {
		if (records === components.length) {
		  	setHasMore(false);
		} else {
		  	setTimeout(() => {
				setRecords(records + itemsPerPage);
		  	}, 2000);
		}
	};

	const handleCheckboxChange = (event) => {
        setInStock(event.target.checked);
    };

	const handleInputKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleEnter();
		}
	};

	const handleEnter = () => {
		if(inStock){
			findInStock();
			setShowStockComponents(true);
		} else {
			findComponents();
			setShowComponents(true);
		}
	};

	const findInStock = () => {

		setIsLoading(true);
	
		const filterString = Object.entries(filtersInStock)
        .map(([key, value]) => {
            if (value === '') {
                return `contains(${key}, '')`;
            } else {
                return `contains(${key}, '${value}')`;
            }
        })
        .join(' and ');
	
		fetch(`${URL}${process.env.REACT_APP_API_STORE}/Store?$filter=${filterString}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
			},
		})
		.then(response => response.json())
		.then((data) => {
			setStockComponents(data.value);
			setIsLoading(false); 
		})
		.catch(error => {
			console.error('Error:', error);
			setIsLoading(false); 
		});
	}

	const findComponents = () => {

		setIsLoading(true);

		const filterString = Object.entries(filters)
        .map(([key, value]) => {
            if (value === '') {
                return `contains(${key}, '')`;
            } else {
                return `contains(${key}, '${value}')`;
            }
        })
        .join(' and ');

		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Components?$filter=${filterString}`,
			{
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				},
			}
		)
		.then(response => response.json())
		.then((data) => {
			setComponents(data.value);
			setIsLoading(false); 
		})
		.catch(error => {
            console.error('Error:', error);
            setIsLoading(false); 
        });
	}

	const handleExcelButton = () => {
		if(inStock){
			createExcelStock();
		} else {
			createExcelComponents();
		}
	};

	const createExcelStock = () => {

		const filterString = Object.entries(filtersInStock)
        .map(([key, value]) => {
            if (value === '') {
                return `contains(${key}, '')`;
            } else {
                return `contains(${key}, '${value}')`;
            }
        })
        .join(' and ');

		return fetch(`${URL}${process.env.REACT_APP_API_STORE}/Store/Xlsx?$filter=${filterString}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (!response.ok) {
				if (response.status === 401) {
					//navigate('/SignIn');
					//localStorage.removeItem('name');
					//localStorage.removeItem('login');
					//localStorage.removeItem('asid');
				} else if (response.status === 500) {
					//setError(true);
					throw new Error("Server error");
				}
			} else {
				return response.blob(); 
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'StockComponents.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			} else {
				throw new Error("Blob is undefined");
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};
	
	const createExcelComponents = () => {

		const filterString = Object.entries(filters)
        .map(([key, value]) => {
            if (value === '') {
                return `contains(${key}, '')`;
            } else {
                return `contains(${key}, '${value}')`;
            }
        })
        .join(' and ');

		return fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Components/Xlsx?$filter=${filterString}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (!response.ok) {
				if (response.status === 401) {
					//navigate('/SignIn');
					//localStorage.removeItem('name');
					//localStorage.removeItem('login');
					//localStorage.removeItem('asid');
				} else if (response.status === 500) {
					//setError(true);
					throw new Error("Server error");
				}
			} else {
				return response.blob(); 
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Components.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			} else {
				throw new Error("Blob is undefined");
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	}; 

	return (
		<>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				sx={{
					position:'absolute',
					top:-11,
					left:10,
					fontSize:20,
					color:'black',
					bgcolor:'#848284',
					lineHeight:0.9,
					zIndex:1,
					fontWeight:'bold'
				}}
			>
				Find Component:
			</Box>
			<Box
				sx={{
					position:'absolute',
					top:'2%',
					left:'2%',
					width:'96%',
					height:'97%',
				}}
			>
				<Box
					sx={{
						position:'absolute',
						top:0,
						left:0,
						bgcolor:'green',
						width:'100%',
						height:'10%',
					}}
				>
					<Grid container spacing={1} columns={5} sx={{ flexGrow: 1 }}>
						<Grid xs={1}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Filter AC Reg.:</FormLabel>
								<Input  
									sx={inputStyle}  
									//value={editedData?.title ?? ''}
									//onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<Grid container spacing={0} columns={4} sx={{ flexGrow: 1 }}>
								<Grid xs={1}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>IPC Pos.:</FormLabel>
										<Input  
											sx={inputStyle}  
											//value={editedData?.title ?? ''}
											//onChange={(event) => handleChange('title', event.target.value)}
										/>
									</FormControl>
								</Grid>
								<Grid xs={1}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>PN:</FormLabel>
										<Input  
											sx={inputStyle}  
											//value={editedData?.title ?? ''}
											//onChange={(event) => handleChange('title', event.target.value)}
										/>
									</FormControl>
								</Grid>
								<Grid xs={1}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>SN:</FormLabel>
										<Input  
											sx={inputStyle}  
											//value={editedData?.title ?? ''}
											//onChange={(event) => handleChange('title', event.target.value)}
										/>
									</FormControl>
								</Grid>
								<Grid xs={1}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>Description:</FormLabel>
										<Input  
											sx={inputStyle}  
											//value={editedData?.title ?? ''}
											//onChange={(event) => handleChange('title', event.target.value)}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						position:'absolute',
						top:'10%',
						left:0,
						bgcolor:'blue',
						width:'100%',
						height:'85%',
					}}
				>

				</Box>
				<Box
					sx={{
						position:'absolute',
						top:'95%',
						left:0,
						bgcolor:'orange',
						width:'100%',
						height:'5%',
					}}
				>

				</Box>
			</Box>
				{/**   
					<Typography
						sx={{
							ml:2,
							fontSize:'24px',
							fontWeight:'500',
							color:'black',
							height:'30px'
						}}
					>
						Find Component:
					</Typography>
					{inStock ? (
						<Box 
							sx={{
								display:'flex',
								mb:1
							}}
						>
							<FormControl
								sx={{
									ml:2
								}}
							>
								<FormLabel 
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									Stock: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filtersInStock.Stock}
									onChange={(event) => setFiltersInStock({ ...filtersInStock, Stock: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}} 
								/>
							</FormControl>
							<FormControl
								sx={{
									ml:3
								}}
							>
								<Box sx={{width:'120px'}}/>
							</FormControl>
							<FormControl>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									PN: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filtersInStock.Pn}
									onChange={(event) => setFiltersInStock ({ ...filtersInStock, Pn: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}} 
								/>
							</FormControl>
							<FormControl>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									SN: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filtersInStock.SerialNumber}
									onChange={(event) => setFiltersInStock({ ...filtersInStock, SerialNumber: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}}
								/>
							</FormControl>
							<FormControl
								sx={{
									mr:2
								}}
							>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									Description: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filtersInStock.Description}
									onChange={(event) => setFiltersInStock({ ...filtersInStock, Description: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}}
								/>
							</FormControl>
						</Box>
					):(
						<Box 
							sx={{
								display:'flex',
								mb:1
							}}
						>
							<FormControl
								sx={{
									ml:2
								}}
							>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									Filter AC Reg.: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filters.AcReg}
									onChange={(event) => setFilters({ ...filters, AcReg: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}} 
								/>
							</FormControl>
							<FormControl
								sx={{
									ml:3
								}}
							>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									IPC Pos.: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filters.IpcPos}
									onChange={(event) => setFilters({ ...filters, IpcPos: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'120px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}} 
								/>
							</FormControl>
							<FormControl>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									PN: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filters.Pn}
									onChange={(event) => setFilters({ ...filters, Pn: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}} 
								/>
							</FormControl>
							<FormControl>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									SN: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filters.SerialNumber}
									onChange={(event) => setFilters({ ...filters, SerialNumber: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}}
								/>
							</FormControl>
							<FormControl
								sx={{
									mr:2
								}}
							>
								<FormLabel
									sx={{
										alignSelf:'center',
										fontSize:'18px',
										margin:'0px'
									}}
								>
									Description: 
								</FormLabel>
								<Input 
									size="sm" 
									value={filters.Description}
									onChange={(event) => setFilters({ ...filters, Description: event.target.value })}
									onKeyDown={handleInputKeyDown}
									sx={{
										width:'140px',
										borderRadius:'0px',
										border:'1px solid black',
										"--Input-focusedThickness": "0px",
										'& input': {
											textAlign: 'center',
										}
									}}
								/>
							</FormControl>
						</Box>  
					)}
					<Box 
						sx={{
							border: '1px solid black',
							height:'378px',
							mx:2,
							overflow: 'hidden',
							width:'849px'
						}}
					>	
						{inStock && !showStockComponent && (
							<Textarea 
								minRows={1}
								disabled 
								variant='outlined' 
								value="Apply Filter to Find Item!" 
								sx={{
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									'--Textarea-paddingBlock':0,
									'--Textarea-paddingInline':0,
									width:'240px',
									height:'auto',
									minHeight:'0px',
									backgroundColor:'#e7e3e7',
									borderRadius:'0px',
									border:'1px solid black',
									borderLeft:'none',
									borderTop:'none',
									fontSize:'16px',
									fontWeight:'100',
									'& textarea': {
										textAlign: 'center',
									}
								}}
							/>
						)}
						{!inStock && !showComponent && (
							<Textarea 
								minRows={1}
								disabled 
								variant='outlined' 
								value="Apply Filter to Find Component!" 
								sx={{
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									'--Textarea-paddingBlock':0,
									'--Textarea-paddingInline':0,
									width:'240px',
									height:'auto',
									minHeight:'0px',
									backgroundColor:'#e7e3e7',
									borderRadius:'0px',
									border:'1px solid black',
									borderLeft:'none',
									borderTop:'none',
									fontSize:'16px',
									fontWeight:'100',
									'& textarea': {
										textAlign: 'center',
									}
								}}
							/>
						)}
						{inStock && showStockComponent && (
							<Box
								sx={{
									backgroundColor:'white',
									height:'100%',
									width:'100%',
									overflowX: 'auto'
								}}
							>
								<StockTable stockComponents={stockComponents}/>
							</Box>
						)}
						{!inStock && showComponent && (
							<Box
								sx={{
									backgroundColor:'white',
									height:'100%',
									width:'100%',
									overflow:'auto'
								}}
							>
								<ComponentsTable components={components}/>
							</Box>
						)}
					</Box> 
					<Box 
						sx={{
							display: 'flex',  
							flexDirection: 'row',  
							justifyContent: 'space-between'
						}}
					>
						<Checkbox 
							label="Find in Stock:" 
							variant="outlined" 
							size="sm"
							onChange={handleCheckboxChange}
							sx={{
								ml:2,
								my:1,
								'--joy-radius-sm':0,
								'--variant-outlinedBorder':'black',
								'--Icon-color':'black',
								fontSize:'20px',
								fontWeight:'bold'
							}}
						/>
						<Button
							key='FindComponents'
							variant="outlined"
							color="neutral"
							onClick={handleExcelButton}
							sx={{
								borderRadius: '0px',
								fontSize: '16px',
								fontWeight:'100',
								backgroundColor:'#c6c3ff',
								color:'black',
								width:'97px',
								height:'26px',
								minHeight:'0px',
								mr:2,
								my:1,
								borderTop:'1px solid white',
								borderRight:'1px solid #424142',
								borderBottom:'1px solid #424142',
								borderLeft:'1px solid white',
								paddingInline:0
							}}
						>
							Excel
						</Button>
					</Box>  
				*/}                          
		</>
	);
}
