import * as React from 'react'
import { defaultProps } from '../../helpers/defaultProps.ts'
import { getDepthPx } from '../../helpers/getDepthPx.ts'
import styles from './style.scss'

interface RenderVerticalLine {
    depth: number
    depthGap: number
    count: number
    nodeHeight: number
    verticalLineOffset?: number
    verticalLineStyles?: React.CSSProperties
    verticalLineTopOffset?: number
    disableTransitions?: boolean
}

export const VerticalLine = ({
    depth,
    depthGap,
    count,
    nodeHeight,
    disableTransitions,
    verticalLineOffset = defaultProps.verticalLineOffset,
    verticalLineStyles = defaultProps.verticalLineStyles,
    verticalLineTopOffset = defaultProps.verticalLineTopOffset
}: RenderVerticalLine) => (
    <div
        style={{
            position: 'absolute',
            //top: 0,
            transition: 'all 0.2s ease',
            left: `${verticalLineOffset}px`,
            marginLeft: getDepthPx(depth, depthGap + 17),
            top: `${verticalLineTopOffset}px`
        }}
    >
        <svg
            style={{
                height: `${Math.floor(count * nodeHeight - (nodeHeight ? nodeHeight / 2 : 0)) -
                    verticalLineTopOffset}px`,
                width: `${verticalLineStyles.strokeWidth}px`,
                position: 'absolute',
                transition: disableTransitions ? 'none' : 'all 0.2s ease'
            }}
        >
            <line x1="0%" y1="0%" x2="0%" y2="100%" className={styles.verticalSvgLine} style={verticalLineStyles} />
        </svg>
    </div>
)
