import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { sha256 } from 'js-sha256';

import URL from '../../../../../../URL.js';

export default function Authentication(props) {
	const [usersList, setUsersList] = React.useState<{login:string,name:string}[]>([])
	const [editedData, setEditedData] = React.useState({login:'', password:'',})
	const [validPassword, setValidPassword] = React.useState(true)
	const [visibalPassword, setVisibalPassword] = React.useState(false)
	const handleClose = () => {
		props.setOpenAuthForm(false)
	};
	const inputStyle = { borderRadius: 0, px:1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };

	let xcenter = (window.innerWidth/2)-300;
	let ycenter = (window.innerHeight/2)-300;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

	//Внесение изменений в data
	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	//Запрос списка пользователей	
	React.useEffect(() => {
		const fetchUsers = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Users?$select=login,name`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setUsersList(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchUsers();
	}, []);

	const handleConfirm = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Users/PasswordVerification(login='${editedData.login}',pwd='${sha256(editedData.password)}')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			if(result.isValid) {
				handleClose()
				if(props.selectedAMP.draft==='N'){
					handleDraft()
				} else if(props.selectedAMP.draft==='Y'){
					handleActivate()
				}
			} else {
				setValidPassword(false)
			}
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const handleDraft = async () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Amp/${props.selectedAMP.id}/AddDraft`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					newAmp:{
						...props.selectedAMP,
						id:0,
						draft:'Y'
					}
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			//props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	const handleActivate = async () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Amp/ActiveDraft?ampId=${props.activeAMP}&draftAmpId=${props.selectedAMP.id}&login=${editedData.login}`, 
			{
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				},
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			//props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'auto',
						width:'600px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 1fr',
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'white',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								startDecorator={<CheckIcon sx={{height:'20px', color:'red'}}/>}
								sx={{
									fontSize:'15px',
									fontWeight:'600',
									color:'black',
								}}
							>
								Authentication
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'black',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Grid container spacing={0} sx={{ flexGrow: 1, bgcolor:'#dcdcf3',p:1 }}>
							<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
								<Typography
									sx={{
										fontSize:'15px',
										fontWeight:'bold',
										color:'#c00000',
									}}
								>
									Authentication is Required to Perform this Action ! Type your Password !
								</Typography>
							</Grid>

							<Grid xs={2}>
								<Typography
									sx={{
										fontSize:'15px',
										color:'black',
									}}
								>
									User ID:
								</Typography>	
							</Grid>
							<Grid xs={3} sx={{px:1}}>
								<Autocomplete
									disableClearable
									options={usersList.map(item=>item?.login)}
									value={{ label: editedData.login || '' }}
									sx={{
										...inputStyle,
									}}
									onChange={(event, value) => handleChange('login', value|| '')}
								/>
							</Grid>
							<Grid xs={7}>
								<Input 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									value={usersList.find(item => item.login === editedData.login)?.name || ''}
								/>
							</Grid>

							<Grid xs={2} sx={{mt:1}}>
								<Typography
									sx={{
										fontSize:'15px',
										color:'black',
									}}
								>
									Password:
								</Typography>	
							</Grid>
							<Grid xs={3} sx={{mt:1, px:1}}>
								<Input 
									endDecorator={visibalPassword?<VisibilityIcon onClick={()=>setVisibalPassword(!visibalPassword)} sx={{width:15}}/>:<VisibilityOffIcon onClick={()=>setVisibalPassword(!visibalPassword)} sx={{width:15}}/>}
									color={validPassword ? 'primary':'danger'}
									type={visibalPassword?'text':'password'}
									sx={{
										...inputStyle, 
										color:validPassword ? '#000084' : '#c00000',
										borderColor:validPassword ? 'black' : '#c00000'
									}} 
									value={editedData.password || ''}
									onChange={(event) => handleChange('password', event.target.value || '')}
								/>
							</Grid>
							<Grid xs={3}></Grid>
							<Grid xs={2} sx={{mt:1, pr:1}}>
								<Button
									onClick={handleClose}
									sx={{
										borderRadius:0,
										height: 30, 
										minHeight: 0,
										width:'100%',
										bgcolor:'#d6d3ce',
										color:'black',
										border:'2px outset grey',
									}}
								>
									Cancel
								</Button>
							</Grid>
							<Grid xs={2} sx={{mt:1, pl:1}}>
								<Button
									onClick={handleConfirm}
									sx={{
										borderRadius:0,
										height: 30, 
										minHeight: 0,
										width:'100%',
										bgcolor:'#d6d3ce',
										color:'black',
										border:'2px outset grey',
									}}
								>
									OK
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}