import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../../URL.js';

export default function Requirements() {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px',alignSelf:'center'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[
		{id:'ataCode',name:'ATA'}, 
		{id:'title',name:'TASK'},
		{id:'acFamily',name:'BASIC_TASK'},
		{id:'etopsSignificant',name:'JIC'},
		{id:'etopsSignificant',name:'TASK_Title'},
		{id:'etopsSignificant',name:'TASK_Description'},
		{id:'etopsSignificant',name:'Task_Type:'},
		{id:'etopsSignificant',name:'Task_Effectivity:'},
		{id:'etopsSignificant',name:'Main_Zone:'},
		{id:'etopsSignificant',name:'Zones:'},
		{id:'etopsSignificant',name:'MRB_Code:'},
		{id:'etopsSignificant',name:'Note:'},
		{id:'etopsSignificant',name:'Base:'},
		{id:'etopsSignificant',name:'MNHR:'},
		{id:'etopsSignificant',name:'FH_Start:'},
		{id:'etopsSignificant',name:'FC_Start:'},
		{id:'etopsSignificant',name:'Calendar_Start:'},
		{id:'etopsSignificant',name:'Calendar_Value_Start:'},
		{id:'etopsSignificant',name:'Doc_Reference_Start:'},
		{id:'etopsSignificant',name:'FH_Interval:'},
		{id:'etopsSignificant',name:'FC_Interval:'},
		{id:'etopsSignificant',name:'Calendar_Interval:'},
		{id:'etopsSignificant',name:'Calendar_Value_Interval:'},
		{id:'etopsSignificant',name:'Doc_Reference_Interval:'},
		{id:'etopsSignificant',name:'Completed_By_Component_Replacement:'},
		{id:'etopsSignificant',name:'IPC_Positions:'},
		{id:'etopsSignificant',name:'FH_Finish:'},
		{id:'etopsSignificant',name:'FC_Finish:'},
		{id:'etopsSignificant',name:'Calendar_Finish:'},
		{id:'etopsSignificant',name:'Calendar_Value_Finish:'},
		{id:'etopsSignificant',name:'Doc_Reference_Finish:'},
		{id:'etopsSignificant',name:'ETOPS:'},
		{id:'etopsSignificant',name:'ETOPS_II:'},
		{id:'etopsSignificant',name:'DI:'},
		{id:'etopsSignificant',name:'CAT_3:'},
		{id:'etopsSignificant',name:'CR:'},
		{id:'etopsSignificant',name:'CDCCL:'},
		{id:'etopsSignificant',name:'ALI:'},
		{id:'etopsSignificant',name:'EWIS:'},
		{id:'etopsSignificant',name:'CPCP:'},
		{id:'etopsSignificant',name:'CMR:'},
		{id:'etopsSignificant',name:'AD:'},
		{id:'etopsSignificant',name:'Instruction_Reference:'},
		{id:'etopsSignificant',name:'APU_Utilization:'},
		{id:'etopsSignificant',name:'NON_Scheduled:'},
	]

	const [data, setData] = React.useState<{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, ataCode:'', title:'', acFamily:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [acFamilies, setAcFamilies] = React.useState ([{}]);
	const [showEditor, setShowEditor] = React.useState (true);
	const [showIntervalFilters, setShowIntervalFilters] = React.useState(false)
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка AC Families
	const fetchFamilies = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftFamilies?$select=acfamily`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setAcFamilies(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		//fetchData();
		//fetchFamilies();
	}, []);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, ataCode:'', title:'', acFamily:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id: 0, ataCode: '', title: '', acFamily: '' };
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Atas/Xlsx()?$filter=contains(atacode, '${filters.ataCode}') and contains(title, '${filters.title}') and contains(acfamily, '${filters.acFamily}')`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'ATA.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}
	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='tableArea'
				sx={{
					position:'relative',
					width:'100%'
				}}
			>
				<Typography 
					id='tableAreaTitle'
					sx={{ 
						position:'absolute',
						fontSize:'16px',
						fontWeight:'bold',
						color:'#000078',
						fontFamily:'Arial, sans-serif',
						left:16
					}}
				>
					Maintenance Requirements:
				</Typography>
				<Box
					id='buttons'
					sx={{
						display:'flex',
						alignItems:'center',
						justifyContent:'center',
						position:'absolute',
						right:16,
						height:40
					}}
				>
					<Tooltip title='Create Excel Report'>
						<IconButton
							variant='plain'
							onClick={createExcel}
							sx={{
								m:0,
							}}
						>
							<img 
								width='35px' 
								src="images/svg/excel.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							onClick={refreshFilters}
							sx={{
								m:0,
							}}
						>
							<img 
								width='35px' 
								src="images/svg/refresh.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
				</Box>	
				<Box
					id='filters'
					sx={{
						display:'flex',
						flexWrap: 'nowrap',
						gap: 0.5,
						alignItems:'flex-end',
						mt:'40px',
						mx:'16px',
					}}
				>
					<FormControl>
						<FormLabel sx={lableStyle}>Task:</FormLabel>
						<Input
							value={filters.ataCode}
							onChange={(event) => handleChange('ataCode', event.target.value)}
							sx={{...inputStyle, width:200}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={lableStyle}>ATA:</FormLabel>
						<Input
							value={filters.title}
							onChange={(event) => handleChange('title', event.target.value)}
							sx={{...inputStyle, width:70}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={lableStyle}>Task Description:</FormLabel>
						<Input
							value={filters.title}
							onChange={(event) => handleChange('title', event.target.value)}
							sx={{...inputStyle, width:150}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={lableStyle}>Type:</FormLabel>
						<Autocomplete
							options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
							sx={{...inputStyle, width:100}}
							onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
							value={!filters.acFamily ? null : { label: filters.acFamily}}
						/>
					</FormControl>
					<Box
						sx={{
							position:'relative'
						}}
					>
						<Tooltip title= 'Open Interval, Check Filters'>
							<Button	
								onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									width:50,
									whiteSpace:'wrap',
									fontSize:12,
									mx:1,
									mb:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
									lineHeight:1
								}}
							>
								Interval Check
							</Button>
						</Tooltip>
						{showIntervalFilters && (
							<Box
								sx={{
									position:'absolute',
									height:300,
									width:260,
									bgcolor:'#d6d3ce',
									border:'1px solid black',
									zIndex:999,
									left:'-100px',
									top:40,
									alignItems:'center'
								}}
							>
								<Grid container spacing={1} sx={{ flexGrow: 1, px:2, py:1 }}>
									<Grid xs={12}>
										<FormControl>
											<FormLabel sx={lableStyle}>Check:</FormLabel>
											<Autocomplete
												options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
												sx={{...inputStyle}}
												onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
												value={!filters.acFamily ? null : { label: filters.acFamily}}
											/>
										</FormControl>
									</Grid>

									<Grid xs={2} sx={{color:'black'}}>
										FH:
									</Grid>
									<Grid xs={10}>
										<Input
											value={filters.title}
											onChange={(event) => handleChange('title', event.target.value)}
											sx={{...inputStyle}}
										/>
									</Grid>

									<Grid xs={2} sx={{color:'black'}}>
										FC:
									</Grid>
									<Grid xs={10}>
										<Input
											value={filters.title}
											onChange={(event) => handleChange('title', event.target.value)}
											sx={{...inputStyle}}
										/>
									</Grid>

									<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
										<Typography sx={{color:'black',fontSize:16}}>
											DY:
										</Typography>
										<Tooltip title='Days Selector'>
											<Checkbox 
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'14px',
													'--Checkbox-size':'15px',
													gap:0.4,
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
												//checked={editedData.shift}
												//onChange={(event) => handleChange('shift', event.target.checked)}
											/>
										</Tooltip>
									</Grid>
									<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
										<Typography sx={{color:'black',fontSize:16}}>
											MO:
										</Typography>
										<Tooltip title='Months Selector'>
											<Checkbox 
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'14px',
													'--Checkbox-size':'15px',
													gap:0.4,
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
												//checked={editedData.shift}
												//onChange={(event) => handleChange('shift', event.target.checked)}
											/>
										</Tooltip>
									</Grid>
									<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
										<Typography sx={{color:'black',fontSize:16}}>
											YR:
										</Typography>
										<Tooltip title='Years Selector'>
											<Checkbox 
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'14px',
													'--Checkbox-size':'15px',
													gap:0.4,
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
												//checked={editedData.shift}
												//onChange={(event) => handleChange('shift', event.target.checked)}
											/>
										</Tooltip>
									</Grid>
											
									<Grid xs={2}></Grid>
									<Grid xs={8}>
										<Input
											value={filters.title}
											onChange={(event) => handleChange('title', event.target.value)}
											sx={{...inputStyle}}
										/>
									</Grid>
									<Grid xs={2}></Grid>

									<Grid xs={2}></Grid>
									<Grid xs={4}>
										<Radio
											color='neutral' 
											variant='outlined'
											label='And'
										/>
									</Grid>	
									<Grid xs={4}>
										<Radio
											color='neutral' 
											variant='outlined'
											label='Or'
										/>
									</Grid>	
									<Grid xs={2}></Grid>

									<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
										<Button
											//onClick={handleConfirm}
											sx={{
												borderRadius:0,
												height: 25, 
												minHeight: 0,
												bgcolor:'#d6d3ce',
												color:'black',
												border:'2px outset grey',
											}}
										>
											Ok
										</Button>
										<Button
											//onClick={handleConfirm}
											sx={{
												borderRadius:0,
												height: 25, 
												minHeight: 0,
												bgcolor:'#d6d3ce',
												color:'black',
												border:'2px outset grey',
											}}
										>
											Cancel
										</Button>
										<Button
											//onClick={handleConfirm}
											sx={{
												borderRadius:0,
												height: 25, 
												minHeight: 0,
												bgcolor:'#d6d3ce',
												color:'black',
												border:'2px outset grey',
											}}
										>
											Reset
										</Button>
									</Grid>
								</Grid>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display:showEditor ? 'none':'flex',
							ml:6
						}}
					>
						<Grid container spacing={1}>
							<Grid xs={2}>
								<Tooltip title='Filter Task with Mandatory Instraction (AD, ALI, CMR) Only'>
									<Checkbox 
										label=':Mand-Lim'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter Task which has Associated TC'>
									<Checkbox 
										label=':TC Associated'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter Task which has Related TC'>
									<Checkbox 
										label=':NON-Scheduled'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter - Flight Length Sensitive - 75.000 FC Maximum'>
									<Checkbox 
										label=':FLS-75'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'16px',
											fontWeight:'bold',
											'--Checkbox-size':'15px',
											color:'#3c0203',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='filter Task with Mandatory Instraction (AD, ALI, CMR) Only'>
									<Checkbox 
										label=':DEL'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'16px',
											fontWeight:'bold',
											'--Checkbox-size':'15px',
											color:'#77040c',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}></Grid>

							<Grid xs={2}>
								<Tooltip title='Filter Task with APU Flight Data Utilization'>
									<Checkbox 
										label=':APU Utiliz.'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter Task which has Related TC'>
									<Checkbox 
										label=':TC Related'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter Task which Inctruction'>
									<Checkbox 
										label=':TC Instruction'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter - Flight Length Sensitive - 56.000 FC Maximum'>
									<Checkbox 
										label=':FLS-56'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'16px',
											fontWeight:'bold',
											'--Checkbox-size':'15px',
											color:'#3c0203',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter - Completed By Component Replacement'>
									<Checkbox 
										label=':COMP'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'16px',
											fontWeight:'bold',
											'--Checkbox-size':'15px',
											color:'#3c0203',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
							<Grid xs={2}>
								<Tooltip title='Filter - Post Threshold Interval'>
									<Checkbox 
										label=':POST'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'16px',
											fontWeight:'bold',
											'--Checkbox-size':'15px',
											color:'#3c0203',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Box
					onClick={() => setShowFilters(!showFilters)}
					sx={{
						border:'1px solid black',
						borderBottom:'none',
						height:'24px',
						display:'flex',
						alignItems:'center',
						justifyContent:'center',
						bgcolor:checkFilters()?'transparent':'#DDE7EE',
						mx:'16px',
					}}
				>
					<ArrowDropDownIcon 
						sx={{
							fontSize: '24px',
							transition: '0.2s',
							transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
						}}
					/>
				</Box>
				<Sheet
					id='tablesSheet'
					variant="outlined"
					sx={{
						position:'absolute',
						width: `calc(100% - 32px)`,
						height:`calc(100% - 130px)`,
						borderRadius: '0px',
						flexShrink: 1,
						overflow: 'auto',
						border: '1px solid black',
						bgcolor:'#848284',
						mx:'16px',
						mt:'0px',
						mb:'10px'
					}}
				>
					<Table
						id='table'
						borderAxis="both"
						stickyHeader
						hoverRow
						sx={{
							'--TableCell-headBackground': '#d6d3ce',
							'--Table-headerUnderlineThickness': '1px',
							'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
							'--TableCell-paddingY': '1px',
							'--TableCell-height': '29px',
							'--TableCell-paddingX': '5px',
							'--TableCell-borderColor': '#939293',
							tableLayout: 'auto', 
							width:'auto',
							borderRadius:'0px'
						}}
					>
						<thead>
							<tr>
								<th style={headerStyle}>
									<Typography
										onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
										endDecorator={<ArrowDropDownIcon />}
										sx={{
											'& svg': {
												color:sort.lable === 'id' ? '#636b74' : 'transparent',
												transition: '0.2s',
												transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
											},
											fontWeight:filters.id != 0 ? 'bold' : 'normal'
										}}
									>
										ID
									</Typography>
								</th>
								{headers.map((row) => (
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === row.id ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters[row.id] ? 'bold' : 'normal'
											}}
										>
											{row.name}
										</Typography>
									</th>
								))}
							</tr>
							{showFilters && (
								<tr>
									<th style={{padding:0}}>
										<Input
											value={filters.id}
											onChange={(event) => handleChange('id', event.target.value)}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters.ataCode}
											onChange={(event) => handleChange('ataCode', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters.title}
											onChange={(event) => handleChange('title', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Autocomplete
											options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
											onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
											value={!filters.acFamily ? null : { label: filters.acFamily}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{backgroundColor:'#d6d3ce'}}></th>
								</tr>
							)}
						</thead>
						<tbody>
							{filteredData.length == 0 && (
								<th colSpan={999}>
									<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
								</th>
							)}
							{filteredData.map((row) => (
								<tr
									key={row.id}
									onClick={() => handleRowSelection(row)}
									style={{ 
										backgroundColor: selectedRow.id === row.id ? '#00cfd6' : 'white',
										color: selectedRow.id === row.id ? 'white' : 'black',
									}}
								>
									<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
									<td style={rowStyle}>{row.id}</td>
									
								</tr>
							))}
						</tbody>
					</Table>
				</Sheet>
			</Box>
		</React.Fragment>
	);
}
