import * as React from 'react'
import { isFunction } from '../../helpers/typeCheckers.ts'
import { defaultProps } from '../../helpers/defaultProps.ts'
import { Arrow } from '../Arrow/index.tsx'
import { Loader } from '../Loader/index.tsx'
import { DefaultNodeProps } from '../../types.ts'
import styles from './style.scss'

const baseUrl = './';

export const DefaultNode: React.FC<DefaultNodeProps> = ({
    displayedName = defaultProps.displayedName,
    node,
    onSelect,
    onToggle
}) => (
    <div 
        onClick={onSelect}
        style={{
            display: 'flex',
            alignItems: 'center'
        }}>
        {(node.hasChildren() || node.options.async) && !node.isLoading() && (
            <Arrow onClick={onToggle} opened={node.isOpened() && !!node.hasChildren()} />
        )}
        {node.isLoading() && <Loader />}

        <img 
            src={require("" + baseUrl + node.data.image)}
            style={{
                height: 22,
                marginLeft: 3,
                width: 22
            }} 
        />
        {
            // get max length + set to min width
            // Для загрузки чилдренов можно решить два волпроса сразу
            // Реализовать загрузку данных по клику на раскравающейся ноде, так чилдрены сами будут грузиться
        Object.keys(node.data.description).map(key => 
        {
            var value = JSON.stringify(node.data?.description[key]).replace(/['"]+/g, '');
            return <label style={{
                fontFamily: 'Courier New',                 
                fontSize: 13, 
                paddingRight: 17, 
                paddingLeft: 7, 
                minWidth: 100, 
                maxWidth: 200, 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                textWrap: 'nowrap'
            }}
            title={value}>
                {value}
            </label>;
        })}
    </div>
)