import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';

import URL from '../../../../../../URL'

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px' };
	const formControlStyle = { my: 0.5 };

	const [reportLoading, setReportLoading] = React.useState(false);

	const [editedData, setEditedData] = React.useState({});
	const [isCodeValid, setIsCodeValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsCodeValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Manufacturers/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности Code
	const checkValidCode = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Manufacturers/ValidCode/${editedData.code}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsCodeValid(result);
			return result;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};
	
	const handleAdd = async () => {
		if (await checkValidCode()) {
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/Manufacturers`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	const handleUpdate = async () => {
		if(editedData.code === props.selectedRow.code || await checkValidCode()){
			setIsCodeValid(true);
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/Manufacturers/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	const handleDelete = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/Manufacturers/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	

		setEditedData({ ...editedData, [property]: value });
	};
	
	return (
		<Box>
			<Typography 
				sx={{
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Manufacturer Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.code && editedData.name ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.code && editedData.name ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={{ ...labelStyle, width: '100%', display: 'flex' }}>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								<Typography>CODE:</Typography>
								<Typography sx={{ color: '#C41C1C', fontSize: '1em', marginLeft: '4px' }}>*</Typography>
							</span>
							<Typography 
								color='danger'
								sx={{ 
									ml: 'auto', 
									mr:2,
									fontSize:14,
									display:!isCodeValid ? 'flex' :'none'
								}}
							>
								Code is already exist
							</Typography>
						</FormLabel>
						<Input 
							color={!isCodeValid? 'danger' : 'primary'}
							sx={{...inputStyle, border:isCodeValid ? '1px solid black':'1px solid #C41C1C', color: isCodeValid ? '#000084':'#C41C1C'}} 
							value={editedData?.code ?? ''} 
							onChange={(event) => handleChange('code', event.target.value)} 
						/>
					</FormControl>
				</Grid>
		
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Name:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.name ?? ''}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Address:</FormLabel>
						<Textarea 
							maxRows={1}
							sx={{
								...inputStyle,
								color:'#000078',
								height:'90px',
								lineHeight:1.2
							}}
							value={editedData?.address ?? ''}
							onChange={(event) => handleChange('address', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Phone:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.phone ?? ''}
							onChange={(event) => handleChange('phone', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Fax:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.fax ?? ''}
							onChange={(event) => handleChange('fax', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>E-Mail:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.email ?? ''}
							onChange={(event) => handleChange('email', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'end'}}>
					<Typography
						sx={{
							ml:1,
							mt:0.5,
							color:'black',
							fontSize:'16px'
						}}
					>
						Contact 1:
					</Typography>
				</Grid>
				<Grid xs={9}>
					<Textarea
						maxRows={1}
						value={editedData?.contact_1 ?? ''}
						sx={{ 
							...inputStyle, 
							height: '90px', 
							my:1,
							lineHeight: '1.2' 
						}}
						onChange={(event) => handleChange('contact_1', event.target.value)}
					/>
				</Grid>

				<Grid xs={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'end'}}>
					<Typography
						sx={{
							ml:1,
							my:0.5,
							color:'black',
							fontSize:'16px'
						}}
					>
						Contact 2:
					</Typography>
				</Grid>
				<Grid xs={9}>
					<Textarea
						maxRows={1}
						value={editedData?.contact_2 ?? ''}
						sx={{ 
							...inputStyle, 
							height: '90px', 
							mt:1,
							lineHeight: '1.2'
						}}
						onChange={(event) => handleChange('contact_2', event.target.value)}
					/>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>WEB:</FormLabel>
						<Textarea 
							maxRows={1}
							sx={{
								...inputStyle,
								color:'#000078',
								height:'120px',
								lineHeight:1.2
							}}
							value={editedData?.web ?? ''}
							onChange={(event) => handleChange('web', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
