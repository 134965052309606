import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';


import URL from '../../../../../../URL.js';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'16px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'16px' }
	const formControlStyle = { my: 1 }

	const [countries, setCountries] = React.useState<{name: string}[]>([]) 
	const [editedData, setEditedData] = React.useState({id:0, icao:'', iata:'', name:'', country:''});
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	//Внесение изменений в data
	const handleChange = (property, value) => {
		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? maxLengthDefault;
		if (value.length > maxLength) return;	

		setEditedData({ ...editedData, [property]: value });
	};

	React.useEffect(() => {
		setEditedData(props.selectedRow);
	}, [props.selectedRow]);

	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	React.useEffect(() => {
		const fetchCountries = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSCountries`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setCountries(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchCountries();
	}, []);

	//Обработчик события добавления новой записи
	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			return response.json();
		})
		.then(data => {
			props.setSelectedRow(data)
			props.reloadTable();
			props.focusRow();
		})
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	//Обработчик события обновления выбранной записи
	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			  }
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			props.focusRow();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));

	}

	//Обработчик события удаления выбранной записи
	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.setSelectedRow({id:0})
			setEditedData({})
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));

	}

	return (
		<Box>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Airline Code Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.icao && editedData.name ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.icao && editedData.name ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={7}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							ICAO Code:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData?.icao || ''}
							onChange={(event) => handleChange('icao', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							IATA Code:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData?.iata || ''}
							onChange={(event) => handleChange('iata', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							Airline Name:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData?.name || ''}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Registration Country:
						</FormLabel>
						<Autocomplete
							options={countries.map(data=> ({ label: data.name }))}
							value={countries.find(item => item.name === editedData.country) ? { label: editedData.country } : null}
							sx={inputStyle}
							onChange={(event, value) => handleChange('country', value ? value.label : '')}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
