import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import AdditionalInfoWnd from './Additional_Info_wnd.tsx';

import URL from '../../../../../../URL'

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px' };
	const formControlStyle = { my: 1 };

	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	const [editedData, setEditedData] = React.useState({});
	const [isModelValid, setIsModelValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsModelValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности ATA
	const checkValidModel = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels/ValidModel()?model='${editedData.model}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsModelValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') { 
			event.preventDefault(); 
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	//Создание PDF отчёта
	{/**
	const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserEditor",
				"isExportAsPicture": false,
				"parameters": [
					{
					"name": 'userId',
					"value": `${props.selectedRow.login}`
					}
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', '/UserEditor.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}
	//Обработчик события добавления новой записи
	const handleAdd = async () => {
		if (await checkValidModel()) {
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						manufacturer:editedData.type ? props.engineType.find(data => data.type === editedData.type)?.manufacturer : ''
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.model === props.selectedRow.model || await checkValidModel()){
			fetch(
				`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						manufacturer:editedData.type ? props.engineType.find(data => data.type === editedData.type)?.manufacturer : ''
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/EngineModels/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	

		setEditedData({ ...editedData, [property]: value });
	};
	
	return (
		<Box>
			{additionalInfo.open && (
				<AdditionalInfoWnd item={additionalInfo.item} value={editedData[additionalInfo.item]} setAdditionalInfo={setAdditionalInfo}/>
			)}
			<Typography 
				sx={{
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Model Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.model && editedData.type ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.model && editedData.type ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={{ ...labelStyle, width: '100%', display: 'flex' }}>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								<Typography>Engine Model:</Typography>
								<Typography sx={{ color: '#C41C1C', fontSize: '1em', marginLeft: '4px' }}>*</Typography>
							</span>
							<Typography 
								color='danger'
								sx={{ 
									ml: 'auto', 
									mr:2,
									fontSize:14,
									display:isModelValid === false? 'flex' :'none'
								}}
							>
								Model is already exist
							</Typography>
						</FormLabel>
						<Input 
							color={!isModelValid ? 'danger' : 'primary'}
							sx={{...inputStyle, border:isModelValid ? '1px solid black':'1px solid #C41C1C', color: isModelValid ? '#000084':'#C41C1C'}} 
							value={editedData?.model ?? ''} 
							onChange={(event) => handleChange('model', event.target.value)} 
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Engine Type:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.engineType.map(data=> ({ label: data.type }))}
							value={{ label: editedData?.type ?? ''}}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('type', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'type')}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<Box
						sx={{
							position:'relative'
						}}
					>
						<Checkbox 
							label='APU:'
							color='primary'
							variant='outlined' 
							sx={{
								color:'#000084',
								position:'absolute',
								right:30
							}}
							checked={editedData.apu == 'Y' ? true : false}
							onChange={(event, value) => handleChange('apu', event.target.checked ? 'Y' : 'N')}
						/>
						<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Manufacturer:</FormLabel>
							<Input 
								size="sm" 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={editedData.type ? props.engineType.find(data => data.type === editedData.type)?.manufacturer : ''}
							/>
						</FormControl>
					</Box>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Engine PN:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.pn ?? ''}
							onChange={(event) => handleChange('pn', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Thrust Rating or Shaft Power:</FormLabel>
						<Textarea 
							maxRows={5}
							sx={{
								...inputStyle,
								color:'#000078',
								height:'150px',
								lineHeight:1.2
							}}
							value={editedData?.thrustRating ?? ''}
							onChange={(event) => handleChange('thrustRating', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Remarks:</FormLabel>
						<Textarea 
							maxRows={5}
							sx={{
								...inputStyle,
								color:'#000078',
								height:'200px',
								lineHeight:1.2
							}}
							value={editedData?.remarks ?? ''}
							onChange={(event) => handleChange('remarks', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}